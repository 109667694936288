/**
 * Megaplan
 */
export function  megaplanCurrentUser($this){
      const qp = $this.getQueryParams();
      // console.log('::megaplanCurrentUser qp=',qp);
      if(qp?.uniqueAccountId && qp?.applicationUuid && qp?.permanentHost){
        //Это точно Мегаплан
        $this.megaplan=true;
      }
      let user_current={};
      return new Promise(async resolve=>{
        if(qp?.userSign){
          const url=`${$this.apiurl}api/megaplan-currentUser`;
          $this.POST(url, qp, async function (res) {
            if($this.isJSON(res)){
              res = JSON.parse(res);
            }
            // console.log('::megaplanCurrentUser res=',res);
            if(res?.data?.id){
              const user = res.data;
              user_current.ID = user.id;
              if(user?.contactInfo?.length>0){
                const email = user.contactInfo.find(el=>el.type=='email');
                if(email){
                  user_current.email =email.value;
                }
              }
              let thumbnail = user?.avatar?.thumbnail;
              if(thumbnail){
                thumbnail = 'https:'+thumbnail.replace("{width}x{height}", "50x50");
              }
              // $this.isAdmin = user.is_admin;
              user_current.NAME = user.firstName;
              user_current.LAST_NAME = user.lastName;
              user_current.SECOND_NAME = user.middleName;
              user_current.PERSONAL_PHOTO = thumbnail;
              user_current.WORK_POSITION = user.position;
              user_current.mp_uid = user.uid;
              if(user.department){
                user_current.UF_DEPARTMENT=[user.department.id];
              }

              $this.member_id = res.member_id;
              $this.portal = res.portal;
              $this.lang = 'ru'; //user.locale;
              $this.isAdmin = user.is_admin;


              resolve(user_current);
            }else{
              resolve(false);
            }
          });
        }else{
          resolve(false);
        }
      });
    }

    function chunkArray(array, chunkSize) {
        // Инициализируем пустой массив для хранения результирующих массивов
        const result = [];
        
        // Проходим по массиву с шагом равным chunkSize
        for (let i = 0; i < array.length; i += chunkSize) {
            // Вырезаем подмассив длиной не более chunkSize и добавляем его в результат
            result.push(array.slice(i, i + chunkSize));
        }
        
        return result;
    }

    export function getUsersMP($this, isActive, arDepartments, usersIds, color) {
      console.log('::getUsersMP',isActive, arDepartments, usersIds);
      return new Promise(async (resolve, reject) => {
          if(usersIds?.length>0){
              const chunkedArray = chunkArray(usersIds, 20);
              // console.log('::getUsersMP chunkedArray=',chunkedArray);
      
              let users = [];
              for(let subArray of chunkedArray){
                  let res = await $this.callMethod('megaplan-users-by-ids', subArray);
                  users = [...users, ...res];
              }
              console.log('::getUsersMP users=',users);
              resolve(users);
          }else{
              $this.callMethod('megaplan-user-for-deps', arDepartments).then(users=>{
                  console.log('::getUsersMP users=',users);
                  resolve(users);
              });
          }
      });
    };

    export function getUsersMegaplan($this, isActive, search=''){
      let filter = search?{filter:search}:{};
      return new Promise((resolve) => {
        $this.callMethod('megaplan-users', filter).then(res=>{
          // console.log('::getUsersMegaplan res=',res);
          let users=[];
          if(res?.data?.length>0){
            for(let item of res.data){
              let email = item?.contactInfo.find(el=>{return el.type=='email'});
              let department = item?.department?.id;
              let avatar = item?.avatar?.thumbnail;
              if(avatar){
                avatar = 'https:'+avatar.replace("{width}x{height}", "50x50");
              }
              let position = item?.position;
              let user = {
                id: item.id,
                name: item.name,
                email: email?email.value:'',
                deps: department?[department]:'',
                photo: avatar?avatar:'',
                type: '',
                pos: position || ''
              }
              users.push(user);

            }

          }
          resolve(users);
        });
      });

    };

    export function extraFilterFnMP($this, needle){
      console.log('::extraFilterFnMP search=',needle)
      return new Promise(resolve => {
        getUsersMegaplan($this, true, needle).then(users=>{
          console.log('::extraFilterFnMP users=',users)
          let list=[];
          if(users?.length>0 && !users?.data) {
            for (let i in users) {
              let item = users[i];
              item.value = item.id;
              item.text = item.name;
              if(item.name)
                list.push({value: item.id, text: item.name, name:item.name, id: item.id, deps: item.deps, departments:item.departments, pos:item.pos, head:item.head, email:item.email});
            }
          }
          console.log('::extraFilterFnMP list=',list)
          resolve(list);
        });
      });

    };

    export function getDepartmentsMP($this) {
      // console.log('::getDepartments');
      return new Promise(function (resolve, reject) {
        $this.callMethod('megaplan-departments').then(departments=>{
          console.log('::getDepartmentsMP departments=',departments);
          resolve(departments);
        });
      });
    };