<template>

  <div v-if="message?.[lang] && messageObj?.[lang]" class="bg-dark text-accent q-pt-none" style="padding-left: 5px; padding-bottom: 100px">
    <!-- <div class="h4 row items-center" v-if="parent.isMobile">
      <div class="col-6 text-accent">
        {{ message[lang].title_lk}}
      </div>
      <div class="col-6 h4 text-positive h4 text-right">
        <q-select borderless style="margin-top: 10px" v-model="lang_model" :options="parent.getListValues(parent.lang_options)" @update:model-value="saveProfile" debounce="500" class="text-accent bg-dark" dense />
      </div>
    </div>

    <div v-else class="row items-center justify-between q-mb-md" style="margin-top: -10px">
      <div class="col-5 text-left q-mb-2">
        <div class="h4 text-accent">
          {{ message[lang].title_lk }}
        </div>
      </div>
      <div class="col-2 h4 text-positive h4 text-right">
        <q-select borderless style="margin-top: 10px" v-model="lang_model" :options="parent.getListValues(parent.lang_options)" @update:model-value="saveProfile" debounce="500" />
      </div>
    </div>

    <q-separator /> -->

    <q-tab-panels v-model="tab" :vertical="!parent.isMobile" class="bg-dark text-negative q-pa-none">
      <!-- Уведомления -->
      <q-tab-panel name="notifications" style="height: 950px; padding-left: 2px; padding-right: 2px; padding-top: 0px">
        <!--  Настройка уведомлений          -->
        <div class="row justify-between">
          <div class="col-5">
            <div class="row q-gutter-x-md">
              <q-btn round flat icon="refresh" color="positive" @click="parent.reloadApp()">
                <q-tooltip class="bg-primary h-tt">{{ parent.message[lang].cache_reset }}</q-tooltip>
              </q-btn>

              <q-btn round flat icon="quiz" color="positive" @click="addQuiz = !addQuiz">
                <q-tooltip class="bg-primary h-tt">{{ parent.messageObj[lang].quiz }}</q-tooltip>
              </q-btn>

              <q-btn round flat icon="mail" color="positive" @click="addMessage = !addMessage">
                <q-tooltip class="bg-primary h-tt">{{ parent.messageObj[lang].message }}</q-tooltip>
              </q-btn>
            </div>
          </div>
          <div class="col-5 text-right">
            <q-btn
              flat
              no-caps
              rounded
              color="positive"
              icon-right="tune"
              :label="message[lang].notes_settings"
              @click="
                settings_dialog = true;
                sendHit();
              "
            />
          </div>
        </div>

        <div v-if="addQuiz" :class="parent.isMobile?'q-ma-xs':'q-ma-lg'">
          <quiz :parent="parent" :lang="lang" :modeItem="'add'" @quiz_save="saveQuiz" @quiz_cancel="addQuiz = !addQuiz" @quiz_delete="deleteQuiz" />
        </div>

        <div v-if="addMessage" class="q-ma-lg">
          <Message :parent="parent" :lang="lang" modeItem="add" @message-save="saveMessage" @message-cancel="addMessage = !addMessage" @message-delete="deleteMessage" />
        </div>

        <q-card class="cat-card bg-negative">
          <q-card-section style="max-height: 900px" class="scroll">

            <div v-if="notifications && Object.keys(notifications).length > 0">
              <div v-for="(item, index) in notifications" :key="item.ID">
                <div v-if="item.message" class="row body-0 q-my-md" style="border-bottom: 1px solid #f2f6ff">
                  <div class="col-xs-12 col-md-6 text-left text-positive h5">
                    <div v-html="item.message"></div>
                  </div>
                  <div class="col-xs-6 col-md-3 text-left text-positive h5">
                    {{ item.date }}
                  </div>
                  <div class="col-xs-6 col-md-3 text-left text-positive h5">
                    <span v-if="item.name">{{ item.name }}</span>
                    <span v-else>{{ message[lang].head_not_sys }}</span>
                  </div>
                </div>
                <div v-else-if="item.STUFF==='message'"  class="parent.isMobile?'q-ma-xs':'q-ma-lg'">
                  <Message 
                    :parent="parent" 
                    :lang="lang" 
                    modeItem="view"
                    :messageItem="item" 
                    :index="index"
                    @message-save="saveMessage" 
                    @message-cancel="addMessage = !addMessage" 
                    @message-delete="deleteMessage" />

                </div>
                <div v-else-if="item.ID"  class="parent.isMobile?'q-ma-xs':'q-ma-lg'">

                    <quiz
                      :parent="parent"
                      :lang="lang"
                      :modeItem="'view'"
                      :quizItem="item"
                      :index="index"
                      @quiz_save="saveQuiz"
                      @quiz_cancel="addQuiz = false"
                      @quiz_delete="deleteQuiz"
                      @quiz_send="sendQuiz"
                      @quiz_rate="sendQuiz"
                      @quiz_res_del="resQuizDel"
                    />

                    <q-inner-loading :showing="item.loading">
                      <q-spinner-oval size="3em" color="positive" />
                    </q-inner-loading>

                </div>
              </div>
            </div>
          </q-card-section>
          <q-inner-loading :showing="loading">
            <q-spinner-oval size="5em" color="primary" />
          </q-inner-loading>
        </q-card>
      </q-tab-panel>

      <!-- Мой профиль Образование и повышение квалификации -->
      <q-tab-panel name="profile" style="height: 1024px; padding-left: 12px; padding-right: 2px">
        <div class="row q-my-sm">
          <div class="col-12 h4 text-primary">
            {{messageObj[lang].portal}}: {{parent.portal}}
          </div>
          <div class="col-6 text-accent h4">
            ID: {{ parent.user_current.ID }},
            {{ parent.user_current.NAME }}
            {{ parent.user_current.SECOND_NAME }}
            {{ parent.user_current.LAST_NAME }},
            {{ parent.user_current.WORK_POSITION }},
            {{ parent.user_current.email }}
          </div>
        </div>

        <div class="text-warning h4" v-if="parent && !parent.user_current.NAME && !parent.user_current.LAST_NAME">
          <q-icon name="info" />
          {{ messageObj[lang].last_name_need }}
        </div>

        <div v-if="parent.SA" class="row justify-between q-my-sm">
          <div class="col-6 text-accent h4">
            <div class="q-my-md q-gutter-y-sm">
              <div class="column text-accent h4">
                {{ messageObj[lang].sub_acc_last_name }}
                <div>
                  <q-input v-model="last_name" borderless style="padding-left: 10px; border-radius: 25px !important; max-width: 500px" class="body-0 bg-negative" @update:model-value="saveProfile" debounce="1000" />
                </div>
              </div>

              <div class="column text-accent h4">
                {{ messageObj[lang].sub_acc_first_name }}
                <div>
                  <q-input v-model="name" borderless style="padding-left: 10px; border-radius: 25px !important; max-width: 500px" class="body-0 bg-negative" @update:model-value="saveProfile" debounce="1000" />
                </div>
              </div>

              <div class="column text-accent h4">
                {{ messageObj[lang].sub_acc_middle_name }}
                <div>
                  <q-input v-model="second_name" borderless style="padding-left: 10px; border-radius: 25px !important; max-width: 500px" class="body-0 bg-negative" @update:model-value="saveProfile" debounce="1000" />
                </div>
              </div>

              <div class="column text-accent h4">
                {{ messageObj[lang].sub_acc_position }}
                <div>
                  <q-input v-model="work_position" borderless style="padding-left: 10px; border-radius: 25px !important; max-width: 500px" class="body-0 bg-negative" @update:model-value="saveProfile" debounce="1000" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 text-accent h4">
            <div class="text-right q-mr-sm">
              <q-btn icon="clear" flat rounded no-caps color="primary" :label="messageObj[lang].clear_profile" @click="clearProfile" />
            </div>
            <div class="text-right q-mr-sm">
              <q-btn icon="password" flat rounded no-caps color="primary" :label="messageObj[lang].change_password" @click="is_change_password = !is_change_password" />
            </div>
            <div v-if="is_change_password" class="h5 text-accent q-gutter-y-sm">
              <div>{{ messageObj[lang].change_password_hint }}</div>
              <div>
                <q-input :label="messageObj[lang].auth_password" v-model="password" type="password" />
              </div>
              <div>
                <q-input :label="messageObj[lang].auth_password2" v-model="password2" type="password" />
              </div>
              <div>
                <q-btn :loading="passw_loading" @click="changePassword" rounded unelevated no-caps color="accent" :disable="!password || password !== password2" :label="messageObj[lang].change_password" />
              </div>
              <div v-if="change_password_success" class="text-primary h4">
                {{ messageObj[lang].change_password_done }}
              </div>
              <div v-else-if="change_password_fail" class="text-warning h4">
                {{ messageObj[lang].change_password_fail }}
              </div>
            </div>
          </div>
        </div>

        <q-separator />

        <div class="h4 text-positive q-my-md">{{ message[lang].e_p_d }}</div>
        <div class="row q-my-md items-center">
          <div class="col-10 h4 text-positive">
            {{ message[lang].experience }}
            <div>
              <q-input :label="message[lang].experience_descr" v-model="experience" type="textarea" borderless style="padding-left: 10px; border-radius: 25px !important" class="body-0 bg-negative" @update:model-value="saveProfile" debounce="1000" />
            </div>
          </div>
        </div>

        <div class="row q-my-md items-center">
          <div class="col-10 h4 text-positive h4">
            {{ message[lang].education }}
            <div>
              <q-input :label="message[lang].education_descr" v-model="education" type="textarea" borderless style="padding-left: 10px; border-radius: 25px !important" class="body-0 bg-negative" @update:model-value="saveProfile" debounce="1000" />
            </div>
          </div>
        </div>
        <!-- Внешние сертификаты -->
        <div class="row items-center">
          <div class="col-12 h4 text-positive h4">{{ message[lang].ext_certs }}</div>
          <div class="col-6">
            <q-file
              v-model="listFiles"
              :label="message[lang].upload"
              :hint="message[lang].upload_hint"
              @update:model-value="fileListChange"
              accept="image/jpeg, image/png, .svg"
              multiple
              use-chips
              append
              borderless
              class="bg-negative"
              style="padding-left: 15px; border-radius: 25px !important"
            >
              <template v-slot:prepend>
                <q-img src="img/docs/img.svg" style="height: 40px; width: 40px" />
              </template>
              <template v-slot:after>
                <q-btn color="primary" dense icon="cloud_upload" round @click="saveCertFiles(true)" :disable="!listFiles || listFiles.length == 0" :loading="isCertsLoading" />
              </template>
            </q-file>
          </div>
          <!-- show list certs -->
          <div class="col-12 q-pa-md q-gutter-md row items-start">
            <div class="row q-gutter-md">
              <div class="q-pa-sm" v-for="(item, index) in ext_certs" :key="item.I">
                <q-img
                  :src="item.H ? item.H : item.data"
                  style="width: 100px; cursor: pointer; border-radius: 15px; border: #dddddd 1px dashed"
                  :loading="isCertsLoading"
                  spinner-size="2rem"
                  spinner-color="positive"
                  @click="
                    popup_index = index;
                    popup_cert = true;
                  "
                />

                <q-btn size="xs" class="absolute all-pointer-events q-ma-xs" round icon="clear" color="positive" @click="delCert(index, item)">
                  <q-tooltip class="bg-primary h-tt">{{ message[lang].delete_cert }}</q-tooltip>
                </q-btn>
              </div>
            </div>
          </div>
        </div>

        <!--  Генерация ключей  -->
        <div class="row justify-end">
          <div class="col-4 offset-6">
            <q-input :borderless="parent.portal != 'novus-km.bitrix24.ru'" v-model="debug">
              <template v-slot:after>
                <q-btn flat round :disable="parent.c53(split_debug(3)) != 6508133905614859" color="transparent" @click="proc_debug" />
              </template>
            </q-input>
          </div>

          <div class="col-8 offset-2 text-positive" v-if="!parent.c53(split_debug(0)) && parent.c53(split_debug(3)) == 6508133905614859 && parent.portal == 'novus-km.bitrix24.ru'">
            <div>
              <q-input label="Имя портала" v-model="debug2" />
            </div>
            <div>
              <q-select map-options option-label="name" option-value="month" :disable="!debug2" label="Лицензия" v-model="lic_model" :options="lic_options[lang]" @update:model-value="lic_upd" />
              <q-input label="Дата окончания" v-model="lic_to" :disable="!debug2" />
            </div>
            <div>
              <q-select map-options option-label="name" option-value="month" :disable="!debug2" label="Диск" v-model="disk_model" :options="disk_options[lang]" @update:model-value="disk_upd" />
              <q-input label="Дата окончания" v-model="disk_to" :disable="!debug2" />
            </div>
            <div>
              <q-select map-options option-label="name" option-value="month" :disable="!debug2" label="Тема" v-model="theme_model" :options="theme_options[lang]" @update:model-value="theme_upd" />
              <q-input label="Дата окончания" v-model="theme_to" :disable="!debug2" />
            </div>
            <div>
              <q-select map-options option-label="name" option-value="month" :disable="!debug2" label="Субаккаунты" v-model="subac_model" :options="subac_options[lang]" @update:model-value="subac_upd" />
              <q-input label="Дата окончания" v-model="subac_to" :disable="!debug2" />
            </div>
            <div v-if="key_str" class="text-accent">
              {{ key_str }}
            </div>
          </div>
        </div>
      </q-tab-panel>
      <!--  Мой рейтинг -->
      <q-tab-panel name="rating" style="min-height: 1024px; padding-left: 2px; padding-right: 2px">
        <div class="row q-my-md">
          <div class="col-xs-12 col-md-4 h4 text-center">
            <div>
              <q-img style="width: 250px; height: 250px" v-if="parent.user_current.PERSONAL_PHOTO" :src="parent.user_current.PERSONAL_PHOTO" />
              <q-avatar v-else size="250px" icon="person_pin" color="accent" />
            </div>
            <q-btn color="primary" @click="showTab = !showTab" round style="margin-top: -40px"
              ><q-avatar size="80px" color="negative" text-color="accent">{{ user_points >= 0 ? user_points : '' }}</q-avatar></q-btn
            >
            <div class="h4 text-primary">{{ parent.getRateName(user_points) }}</div>
          </div>
          <div class="col-xs-12 col-md-6 h5">
            <q-card class="cat-card bg-negative" style="min-height: 250px; margin-top: 0px" v-if="showTab" transition-show="slide-right">
              <q-card-section style="max-height: 300px" class="scroll">
                <div class="row body-0 q-my-md" v-for="item in parent.rating" :key="item.id" style="border-bottom: 1px solid #f2f6ff">
                  <div class="col-2 text-center">{{ item.from }}</div>
                  <div class="col-3 text-left">{{ item.to }}</div>
                  <div class="col-7 text-left">{{ item.title }}</div>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>

        <div class="prof_descr q-my-lg">
          <q-banner dense rounded class="bg-negative">
            <template v-slot:avatar>
              <q-icon name="check_circle" color="secondary" />
            </template>
            {{ message[lang].mypoints_descr }}
          </q-banner>
        </div>

        <q-expansion-item v-if="user_points_list && user_points_list.length > 0" :label="message[lang].score_table" expand-icon default-opened header-class="text-primary h4 bg-info" style="max-width: 700px">
          <div class="row q-pa-md h4 text-accent text-bold">
            <div class="col-2">{{ message[lang].point_date }}</div>
            <div class="col-2">{{ message[lang].point_type }}</div>
            <div class="col-1 text-center">{{ message[lang].point_id }}</div>
            <div class="col-6">{{ message[lang].point_name }}</div>
            <div class="col-1 text-center">{{ message[lang].point_pt }}</div>
          </div>
          <q-separator inset color="positive" />
          <div v-for="item in user_points_list" :key="item.id">
            <div class="row q-pa-md h5 text-accent">
              <div class="col-2">{{ item.dt }}</div>
              <div class="col-2">{{ item.type }}</div>
              <div class="col-1">{{ item.id }}</div>
              <div class="col-6">{{ item.name }}</div>
              <div class="col-1 text-center">{{ item.pt }}</div>
            </div>
            <q-separator inset color="positive" />
          </div>
        </q-expansion-item>
        <q-inner-loading :showing="loading_rating">
          <q-spinner-oval size="5em" color="primary" />
        </q-inner-loading>
      </q-tab-panel>
      <!-- Мои тесты-->
      <q-tab-panel name="tests" style="height: 750px; padding-left: 2px; padding-right: 2px">
        <!-- Назначенные тесты  -->
        <div class="text-positive h4 text-center q-pa-md">
          {{ message[lang].scheduled_table }}
        </div>
        <q-card class="cat-card bg-negative">
          <q-card-section style="max-height: 700px" class="scroll">
            <div class="row body-0 q-my-md items-center" style="border-bottom: 1px solid #f2f6ff">
              <div class="col-xs-2 col-md-1 h5 text-left">{{ message[lang].col_1 }}</div>
              <div class="col-xs-10 col-md-6 h5 text-left">{{ message[lang].col_2 }}</div>
              <div class="col-xs-6 col-md-2 h5 text-left">{{ message[lang].col_3 }}</div>
              <div class="col-xs-3 col-md-1 h5 text-left">{{ message[lang].col_4 }}</div>
              <div class="col-xs-3 col-md-2 h5 text-left">{{ message[lang].col_5 }}</div>
            </div>
            <div v-for="(item, index) in flt_scheduled_tests" :key="index">
              <div class="row body-0 q-my-md items-center" style="border-bottom: 1px solid #f2f6ff">
                <div class="col-xs-2 col-md-1 text-left">{{ item.value }}</div>
                <div class="col-xs-10 col-md-6 text-left">{{ item.text }}</div>
                <div class="col-xs-6 col-md-2 text-left">
                  <div class="text-warning">{{ message[lang].scheduled_assign }}</div>
                  <span v-if="item.date_start">{{ item.date_start }}</span
                  >&nbsp;
                  <span v-if="item.date_finish">{{ item.date_finish }}</span>
                </div>
                <div class="col-xs-2 col-md-1 text-left"></div>
                <div class="col-xs-4 col-md-2 text-left">
                  <q-chip clickable @click="toGoAgain(item.value, 'test', item.sched_id)" color="primary" text-color="negative" icon-right="navigate_next">
                    {{ message[lang].scheduled_go }}
                  </q-chip>
                  <div class="q-mt-xs" v-if="parseInt(item.show_results) > 0">
                    <q-chip clickable @click="showResults(item)" color="secondary" text-color="negative" icon-right="navigate_next">
                      {{ message[lang].show_results }}
                    </q-chip>
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
          <q-inner-loading :showing="loading_user_tests">
            <q-spinner-oval size="5em" color="primary" />
          </q-inner-loading>
        </q-card>

        <!--  Пройденные или в процессе тесты  -->
        <div class="text-positive h4 h4 text-center q-pa-md">
          {{ message[lang].process_table }}
        </div>
        <q-card class="cat-card bg-negative text-positive">
          <q-card-section style="max-height: 700px" class="scroll">
            <div v-for="groupTestItem in flt_user_tests" :key="groupTestItem.test.test_id">
              <q-expansion-item v-if="groupTestItem.test" header-class="text-accent" :label="groupTestItem.test.test_id + ' ' + groupTestItem.test.test_name" style="background-color: #ffffff">
                <div class="text-right">
                  <q-chip clickable @click="toGoAgain(groupTestItem.test.test_id, 'test')" color="primary" text-color="negative" icon-right="navigate_next">
                    {{ message[lang].toGoAgain }}
                  </q-chip>
                </div>

                <div class="row body-0 q-my-md q-pl-lg" v-for="(item, index) in groupTestItem.tests" :key="item.test_id" style="border-bottom: 1px solid #f2f6ff">
                  <div class="col-12 text-right q-pa-xs" v-if="item.sched_id">
                    <q-btn size="xs" round unelevated icon="navigate_next" color="primary" @click="toGoAgain(item.test_id, 'test', item.sched_id)">
                      <q-tooltip class="bg-primary h-tt">{{ message[lang].go_scheduled }}</q-tooltip>
                    </q-btn>
                  </div>

                  <div class="col-6 text-left">
                    {{ message[lang].attempt }} №
                    {{ showAttempt(groupTestItem.tests, index) }}
                  </div>

                  <div class="col-2 text-left">
                    <div
                      v-bind:class="[
                        {
                          'text-success': item.test_passed,
                          'text-danger': !item.test_passed,
                        },
                      ]"
                    >
                      {{ item.test_passed ? message[lang].passed : message[lang].nopass }}
                    </div>
                  </div>

                  <div class="col-xs-2 col-md-1 text-left">{{ item.test_result }}</div>
                  <div class="col-xs-2 col-md-2 text-left">
                    <q-btn v-if="parseInt(item.show_results) > 0" flat rounded no-caps @click="showResults(item)" color="secondary" :label="message[lang].show_results" />
                  </div>

                  <div class="col-12 row h5 text-positive">
                    <div class="col-4">
                      <span v-if="item.isScheduled">{{ message[lang].scheduled_assign }}</span>
                      <span v-else>{{ message[lang].self_recording }}</span>
                    </div>
                    <div class="col-4">
                      {{ message[lang].dt_start }}:
                      <span v-if="item.date_start">{{ item.date_start }}</span>
                      <span v-else-if="item.startTime">{{ showStarttime(item.startTime) }}</span>
                    </div>
                    <div class="col-4">
                      {{ message[lang].dt_finish }}:
                      <span v-if="item.date_finish">{{ item.date_finish }}</span>
                      <span v-else-if="item.endTime">{{ showStarttime(item.endTime) }}</span>
                    </div>
                  </div>
                </div>
              </q-expansion-item>
            </div>

            <div class="text-positive h4">{{ message[lang].col_4_descr }}</div>
          </q-card-section>
          <q-inner-loading :showing="loading_user_tests">
            <q-spinner-oval size="5em" color="primary" />
          </q-inner-loading>
        </q-card>
      </q-tab-panel>
      <!-- Мои курсы-->
      <q-tab-panel name="courses" style="height: 750px; padding-left: 2px; padding-right: 2px">
        <!-- Назначенные курсы  -->
        <div class="text-positive h4 h4 text-center q-pa-md">
          {{ message[lang].scheduled_table }}
        </div>
        <q-card class="cat-card bg-negative">
          <q-card-section style="max-height: 400px" class="scroll">
            <div class="row body-0 q-my-md items-center" style="border-bottom: 1px solid #f2f6ff">
              <div class="col-xs-2 col-md-2 h5 text-left">
                {{ message[lang].head_course_id }}
              </div>
              <div class="col-xs-10 col-md-6 h5 text-left">
                {{ message[lang].head_course_name }}
              </div>
              <div class="offset-xs-3 col-xs-5 offset-md-0 col-md-2 h5 text-left">
                {{ message[lang].head_course_status }}
              </div>
              <div class="col-xs-4 col-md-2 h5 text-left">
                {{ message[lang].head_course_act }}
              </div>
            </div>
            <div v-for="(item, index) in scheduled_courses" :key="index">
              <!--                  -->
              <div class="row body-0 q-my-md items-center" style="border-bottom: 1px solid #f2f6ff">
                <div class="col-xs-2 col-md-1 text-left">
                  {{ item.value }}<q-tooltip class="bg-primary h-tt">{{ item.sched_id }}</q-tooltip>
                </div>
                <div class="col-xs-10 col-md-7 text-left" style="padding-left: 16px">
                  {{ item.text }}
                </div>
                <div class="offset-xs-2 col-xs-5 offset-md-0 col-md-2 text-left q-pl-xs">
                  <div class="text-warning">
                    {{ message[lang].scheduled_assign }}
                    <span v-if="item.sched_id === 'ipr'">({{ message[lang].ipr }})</span>
                  </div>
                  <span v-if="item.date_start">{{ customFormatter(item.date_start) }}</span
                  >&nbsp;
                  <span v-if="item.date_finish">{{ customFormatter(item.date_finish) }}</span>
                </div>
                <div class="col-xs-5 col-md-2 text-left">
                  <q-chip clickable @click="toGoAgain(item.value, 'course', item.sched_id)" color="primary" text-color="negative" icon-right="navigate_next">
                    {{ message[lang].scheduled_go }}
                  </q-chip>
                </div>
              </div>
              <!--                  -->
            </div>
          </q-card-section>
          <q-inner-loading :showing="loading_user_courses">
            <q-spinner-oval size="5em" color="primary" />
          </q-inner-loading>
        </q-card>
        <!--  Пройденные или в процессе курсы  -->
        <div class="text-positive h4 h4 text-center q-pa-md">
          {{ message[lang].process_table }}
        </div>
        <q-card class="cat-card bg-negative text-positive">
          <q-card-section style="max-height: 700px" class="scroll">
            <div v-for="(groupCourseItem, index) in flt_user_courses" :key="index">
              <q-expansion-item
                v-if="groupCourseItem.course"
                :header-class="groupCourseItem.course.sched_id === 'ipr' ? 'text-warning' : 'text-accent'"
                :label="groupCourseItem.course.course_id + ' ' + groupCourseItem.course.course_name + (groupCourseItem.course.sched_id === 'ipr' ? ' (' + message[lang].ipr + ')' : '')"
                style="background-color: #ffffff"
              >
                <div class="text-right">
                  <q-chip clickable @click="toGoAgain(groupCourseItem.course.course_id, 'course', groupCourseItem.course.sched_id)" color="primary" text-color="negative" icon-right="navigate_next">
                    {{ message[lang].toGoAgain }}
                  </q-chip>
                </div>
                <!-- Попытки      -->
                <div class="row body-0 q-my-md q-ml-md items-start" v-for="(item, index) in groupCourseItem.courses" :key="item.course_id" style="border-bottom: 1px solid #f2f6ff">
                  <div class="col-xs-12 col-md-10 text-left q-my-md">
                    <q-expansion-item :label="message[lang].attempt + ' №' + (index + 1) + ' -- ' + showStarttime(item.startTime)" dense style="background-color: #f1f5fc">
                      <div class="text-right q-pa-xs" v-if="item.sched_id">
                        <q-btn size="xs" round unelevated icon="navigate_next" color="primary" @click="toGoAgain(item.course_id, 'course', item.sched_id)">
                          <q-tooltip class="bg-primary h-tt">{{ message[lang].go_scheduled }}</q-tooltip>
                        </q-btn>
                      </div>
                      <!-- Тесты -->
                      <div class="row q-ml-md" v-for="test in item.tests" :key="test.test_id" :class="accord_row2">
                        <div class="col-xs-2 col-md-2">{{ test.test_id }} {{ message[lang].thetest }}</div>
                        <div class="col-xs-10 col-md-5">{{ test.test_name }}</div>
                        <div
                          class="col-xs-6 col-md-2"
                          v-bind:class="[
                            {
                              'text-secondary': test.test_passed,
                              'text-warning': !test.test_passed,
                            },
                          ]"
                        >
                          {{ test.test_passed ? message[lang].passed : message[lang].nopass }}
                          <div class="text-positive h5">
                            {{ showStarttime(test.startTime) }}
                          </div>
                        </div>
                        <div class="col-xs-6 col-md-3">
                          {{ test.isEnd ? message[lang].over : message[lang].noover }}
                          <div v-if="parseInt(test.show_results) > 0">
                            <q-chip clickable @click="showResults(test)" color="secondary" text-color="negative" icon-right="navigate_next">
                              {{ message[lang].show_results }}
                            </q-chip>
                          </div>
                          <div v-else-if="test.test_result">{{ test.test_result }}</div>
                          <div v-else>{{ test.result }}</div>
                        </div>
                      </div>
                      <!--  Уроки  -->
                      <div class="row q-ml-md" v-for="lesson in item.lessons" :key="lesson.lesson_id" :class="accord_row2">
                        <div class="col-xs-2 col-md-2">{{ lesson.lesson_id }} {{ message[lang].thelesson }}</div>
                        <div class="col-xs-10 col-md-5">{{ lesson.lesson_name }}</div>
                        <div
                          class="offset-xs-6 col-xs-6 offset-md-0 col-md-2"
                          v-bind:class="[
                            {
                              'text-secondary': lesson.test_passed === true || lesson.test_passed == 'Y',
                              'text-warning': lesson.test_passed !== true && lesson.test_passed != 'Y',
                            },
                          ]"
                        >
                          {{ lesson.test_passed === true || lesson.test_passed == 'Y' ? message[lang].passed : message[lang].nopass }}
                        </div>
                      </div>
                    </q-expansion-item>
                  </div>
                  <div class="offset-xs-3 col-xs-5 offset-md-0 col-md-2 text-left q-pl-xs">
                    <div v-if="item.passed" class="text-left text-success">
                      {{ message[lang].passed }}
                    </div>
                    <div v-else class="text-left text-danger">
                      {{ message[lang].nopass }}
                    </div>
                    <!--                          <div v-if="!item.completed" class="text-left text-danger" >-->
                    <!--                            {{message[lang].in_progress}}-->
                    <!--                          </div>-->
                    <!--                          <div v-else>-->
                    <!--                            {{message[lang].over}} <div class="small">{{item.completed}}</div>-->
                    <!--                          </div>-->

                    <div v-if="item.isScheduled">({{ message[lang].scheduled_assign }})</div>
                    <div v-else>({{ message[lang].self_recording }})</div>
                    <span v-if="item.date_start" class="small">{{ item.date_start }}</span
                    >&nbsp;
                    <span v-if="item.date_finish" class="small">{{ item.date_finish }}</span>
                  </div>
                  <!--                      <div class="col-12">{{item}}</div>-->
                  <!--                  <div class="col-xs-4 col-md-2 text-left">-->
                  <!--                    <q-chip clickable @click="toGoAgain(item.test_id, 'course')" color="primary" text-color="white" icon-right="navigate_next">-->
                  <!--                      {{message[lang].toGoAgain}}-->
                  <!--                    </q-chip>-->
                  <!--                  </div>-->
                </div>
              </q-expansion-item>
            </div>
          </q-card-section>
          <q-inner-loading :showing="loading_user_courses">
            <q-spinner-oval size="5em" color="primary" />
          </q-inner-loading>
        </q-card>
      </q-tab-panel>

      <!-- Мои оценки 180/360 -->
      <q-tab-panel class="scroll" name="assessments" style="height: 1350px; padding-left: 2px; padding-right: 2px">
        <degree360-list-lk v-if="isShowDegree360ListLk" @self_close="self_close" @self_task_update="self_task_update" v-bind:parent="parent" v-bind:lang="lang" v-bind:back="'studentProfile'" v-bind:selfItem="selfItem" />
        <!-- Мои согласования -->
        <degree360-list
          v-if="isShowDegree360List"
          @self_edit="self_edit"
          @degree360_recalc="countMyAssBackground"
          @self_task_update="self_task_update"
          v-bind:parent="parent"
          v-bind:lang="lang"
          v-bind:back="backScreen"
          v-bind:mntTab="null"
          v-bind:hideTabs="true"
        ></degree360-list>

        <q-inner-loading :showing="isLoading">
          <q-spinner-oval size="5em" color="primary" />
        </q-inner-loading>
      </q-tab-panel>
      <!-- Мои проверки  -->
      <q-tab-panel name="iresponsible" style="height: 750px; padding-left: 2px; padding-right: 2px">
        <q-card class="cat-card bg-negative">
          <q-card-section class="text-right">
            <q-btn :loading="loading" v-if="!isShowChecked" flat color="primary" @click="toggleChecked(true)">{{ message[lang].showChecked }}</q-btn>
            <q-btn :loading="loading" v-else flat color="secondary" @click="toggleChecked(false)">{{ message[lang].hideChecked }}</q-btn>
          </q-card-section>

          <q-card-section style="max-height: 700px" class="scroll">
            <div class="row">
              <div class="col-xs-12 col-md-5 h4">{{ message[lang].opn_col_1 }}</div>
              <div class="col-xs-12 col-md-4 h4">{{ message[lang].opn_col_2 }}</div>
              <div class="offset-xs-6 col-xs-6 offset-md-0 col-md-3 h4">
                {{ message[lang].opn_col_3 }}
              </div>
            </div>
            <div v-for="(item, index) in parent.listOpens" :key="item.ID" style="border-bottom: 1px solid #f2f6ff">
              <div class="row body-0 q-py-sm q-my-md items-start" v-if="item.DETAIL_TEXT.q && (isShowChecked || !item.DETAIL_TEXT.s)">
                <div class="col-xs-12 col-md-1 text-left">
                  {{ item.ID }}
                  <span v-if="item.DETAIL_TEXT.s">
                    <q-icon size="lg" :color="item.DETAIL_TEXT.s === 'Y' ? 'secondary' : 'warning'" name="done" />
                  </span>
                  <div class="text-positive h5">{{ item.DATE_CREATE }}</div>
                </div>
                <div class="offset-xs-1 col-xs-11 offset-md-0 col-md-4 text-left q-my-sm">
                  {{ parent.removeHtmlTags(item.DETAIL_TEXT.q) }}
                  <div>{{ showTime(item.TIMESTAMP_X) }}</div>
                </div>
                <div class="offset-xs-1 col-xs-11 offset-md-0 col-md-4 text-left q-my-sm">
                  {{ parent.showBase64Text(item.DETAIL_TEXT.a) }}
                </div>
                <div class="col-xs-8 offset-md-0 col-md-2 text-left q-my-sm">
                  <q-select rounded outlined v-model="status_model[index]" :label="status_options[lang][0].text" :options="parent.getListValues(status_options[lang])" @update:model-value="change_status_model(index)" />
                </div>
                <div class="col-xs-4 col-md-1 text-center">
                  <q-btn :disable="status_model[index] === status_options[lang][0].text" color="primary" round size="md" @click="saveRespScore(item, index)" icon="chevron_right">
                    <q-tooltip class="bg-primary h-tt">{{ message[lang].saveChanges }}</q-tooltip>
                  </q-btn>
                </div>

                <!-- Files-->
                <div class="col-12 q-pa-md">
                  <div class="row q-gutter-md">
                    <div v-for="itemFile in item.DETAIL_TEXT.f" :key="itemFile.I">
                      <span v-if="itemFile.type == 'image/jpeg'">
                        <img :src="itemFile.H" style="max-width: 700px; max-height: 300px" />
                      </span>
                      <span v-else>
                        <a :href="itemFile.H" target="_blank">{{ itemFile.N }}</a>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-11 offset-1 text-left">
                  <q-input
                    :label="message[lang].yoursComments"
                    v-model="item.DETAIL_TEXT.c"
                    type="textarea"
                    autogrow
                    borderless
                    style="padding-left: 10px; border-radius: 25px !important; background-color: #f2f6ff"
                    class="body-0"
                    :rules="[(val) => val.length > 0 || message[lang].requaried_field]"
                  />
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-tab-panel>
      <!-- Мои сертификаты  -->
      <q-tab-panel name="certs" style="min-height: 50px; padding-left: 5px; padding-right: 5px">
        <q-select
          class="body-0 q-px-lg bg-negative"
          borderless
          v-model="my_cert"
          use-input
          option-label="name"
          map-options
          input-debounce="0"
          :label="message[lang].tests_courses"
          :options="myCerts_opts"
          @filter="filterFn_cert"
          style="padding-left: 15px; border-radius: 25px !important"
          :loading="userCoursesLoading"
          :disable="!myCerts_opts || !myCerts_opts.length"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-accent">
                {{ message[lang].no_results }}
              </q-item-section>
            </q-item>
          </template>
        </q-select>

        <!--  Кнопки              -->
        <div v-if="my_cert" class="q-my-sm q-gutter-x-md">
          <q-btn no-caps unelevated outline rounded color="primary" icon="img:img/docs/png.svg" :label="message[lang].save_png" @click="savePNG" />
          <q-btn no-caps unelevated outline rounded color="primary" icon="img:img/docs/pdf.svg" :label="message[lang].save_png" @click="savePDF" />
        </div>
      </q-tab-panel>
      <!-- Мои компетенции  -->
      <q-tab-panel name="competence" style="min-height: 50px; padding-left: 5px; padding-right: 5px">
        <div class="row q-py-sm items-center" v-for="(item, index) in listCompetence" :key="index" style="border-bottom: #eeeeee 1px solid">
          <div class="col-1 q-gutter-x-sm">
            <!-- Показать Индикаторы-->
            <q-btn round dense flat icon="view_headline" color="positive" @click="showInd[index] = !showInd[index]" />
          </div>
          <div class="col-9 text-accent">
            {{ item.name }}
          </div>
          <div class="col-2">
            <q-chip outline rounded color="primary" v-if="item.published === 'Y'" :label="message[lang].published" />
            <q-chip outline rounded color="secondary" v-else-if="item.on_appr === 'Y'" :label="message[lang].on_appr" />
          </div>

          <!-- Профиль -->
          <div class="col-12" v-if="showInd[index]" style="border-radius: 25px; border: #eeeeee 1px solid">
            <!-- Блок согласования -->
            <div class="row text-positive h5">
              <div v-if="item.appr && item.appr.length > 0" class="col-3">
                <!--Согласующие-->
                {{ message[lang].appr }}
              </div>
              <div v-if="item.agr" class="col-3">
                <!--Утверждающий -->
                {{ message[lang].agr }}
              </div>
            </div>
            <div class="row">
              <!--Согласующие-->
              <div v-if="item.appr && item.appr.length > 0" class="col-3">
                <div class="row items-center" v-for="(user, u_index) in item.appr" :key="u_index">
                  <!--Согласование-->
                  <div class="col-2">
                    <q-toggle :disable="item.published === 'Y'" v-if="parent.user_current.ID == user.id && item.on_appr === 'Y'" v-model="user.checked" color="secondary" size="xs" @update:model-value="chekForPublish(index)">
                      <q-tooltip class="bg-transparent text-secondary" :offset="[5, 5]">
                        {{ message[lang].to_approove }}
                      </q-tooltip>
                    </q-toggle>
                  </div>
                  <!--Согласующий-->
                  <div class="col-10">
                    <q-chip
                      :removable="item.own_id == parent.user_current.ID && item.published !== 'Y' && item.on_appr !== 'Y'"
                      @remove="
                        item.appr.splice(u_index, 1);
                        saveCompetence(index, null, 'profile');
                      "
                      size="md"
                      :color="user.checked ? 'secondary' : 'positive'"
                      :icon="user.checked ? 'done' : ''"
                      :text-color="item.agr && item.agr.checked ? 'negative' : 'accent'"
                    >
                      <span style="max-width: 130px; overflow: hidden">{{ user.name }}</span>
                    </q-chip>
                  </div>
                </div>
              </div>

              <!--Утверждающий-->
              <div v-if="item.agr" class="col-3">
                <div class="row items-center">
                  <!--утверждение-->
                  <div class="col-2">
                    <q-toggle :disable="!isAllApprooved(index)" v-if="parent.user_current.ID == item.agr.id && item.on_appr === 'Y'" v-model="item.agr.checked" color="secondary" size="xs" @update:model-value="chekForPublish(index)">
                      <q-tooltip class="bg-transparent text-secondary" :offset="[5, 5]">
                        {{ message[lang].to_agree }}
                      </q-tooltip>
                    </q-toggle>
                  </div>
                  <!--Утверждающий-->
                  <div class="col-10">
                    <q-chip
                      v-if="item.agr"
                      :removable="item.own_id == parent.user_current.ID && (item.published === 'Y' || item.on_appr === 'Y')"
                      @remove="
                        item.agr = '';
                        saveCompetence(index, null, 'profile');
                      "
                      size="md"
                      :color="item.agr && item.agr.checked ? 'secondary' : 'positive'"
                      :icon="item.agr && item.agr.checked ? 'done' : ''"
                      :text-color="item.agr && item.agr.checked ? 'white' : 'black'"
                    >
                      {{ item.agr.name }}
                    </q-chip>
                  </div>
                </div>
              </div>
            </div>

            <div class="row items-center justify-between q-pa-sm text-positive h4">
              <!-- сотрудники -->
              <div class="col-12">
                <div>{{ message[lang].users }}</div>
                <div>
                  <q-chip outline v-for="(user, u_index) in item.users" :key="u_index" color="accent" size="md">
                    {{ user.name }}
                  </q-chip>
                </div>
              </div>

              <!-- компетенции -->
              <div class="col-12">
                <div v-for="(comp, c_index) in item.comps" :key="c_index">
                  <div class="row items-center q-my-md">
                    <div class="col-8">
                      {{ comp.name }}
                    </div>
                    <div class="col-3" v-if="comp.scale">
                      <!-- Шкала оценок-->
                      {{ comp.scale.name }}
                    </div>
                  </div>
                  <q-separator inset />
                  <!-- Индикаторы-->
                  <div class="row q-py-sm items-center justify-between" v-for="(indicator, ind) in comp.indicators" :key="ind">
                    <div class="col-5 offset-2 q-pl-md">
                      {{ indicator.value }}
                    </div>
                    <div class="col-5">
                      <q-select v-if="comp.scale && comp.scale.indicators" readonly v-model="indicator.target_sc" option-label="name" map-options :label="message[lang].target_sc" :options="comp.scale.indicators">
                        <q-tooltip
                          v-if="indicator.target_sc"
                          class="bg-primary h-tt"
                          v-html="
                            comp.scale.indicators.find((el) => {
                              return el.value == indicator.target_sc.value;
                            }).desc
                          "
                        />
                      </q-select>
                    </div>
                  </div>

                  <div class="col-12"><q-separator inset /></div>
                </div>
              </div>
            </div>

            <div v-if="isImApprInProfile(item)">
              <Comments v-bind:lang="lang" v-bind:entity-id="`comps_${item.id}`" v-bind:user_current="parent.user_current" v-bind:parent="parent" v-bind:mode="'list'" />
            </div>
          </div>
        </div>
      </q-tab-panel>
      <!--Мероприятия-->
      <q-tab-panel name="events" style="min-height: 800px; padding-left: 5px; padding-right: 5px">
        <events-list @addNewEvent="addNewEvent" @editEvent="editEvent" @goback="goback" v-bind:parent="parent" v-bind:lang="lang" v-bind:back="backScreen" v-bind:mode="'LK'"></events-list>
      </q-tab-panel>
      <!-- Программы -->
      <q-tab-panel name="programs" style="min-height: 800px; padding-left: 5px; padding-right: 5px">
        <list-programs @goback="goback" v-bind:parent="parent" v-bind:lang="lang" v-bind:back="backScreen" v-bind:mntTab="'list_user_schedules'" v-bind:hideTabs="true" v-bind:mode="'LK'"></list-programs>
      </q-tab-panel>
      <!-- ИПР -->
      <q-tab-panel name="lk_ipr" style="min-height: 800px; padding-left: 5px; padding-right: 5px">
        <ipr @goback="goback" @courseView="courseView" @iprChange="iprUpdateScheduledCourses" v-bind:parent="parent" v-bind:lang="lang" v-bind:back="backScreen" v-bind:uid="parent.user_current.ID" v-bind:mode="'LK'"></ipr>
      </q-tab-panel>
      <!-- Отчеты -->
      <q-tab-panel name="lk_report" style="min-height: 800px; padding-left: 5px; padding-right: 5px">
        <reports @goback="goback" v-bind:parent="parent" v-bind:lang="lang" v-bind:back="backScreen"></reports>
      </q-tab-panel>
    </q-tab-panels>

    <div class="q-pa-md">
      <canvas ref="canvas" id="canvas" :width="width" :height="height"></canvas>
    </div>
  </div>

  <q-dialog v-model="popup_cert">
    <q-card class="column q-pa-none q-ma-none bg-transparent" style="width: 700px; max-width: 80vw; box-shadow: none">
      <q-img :src="ext_certs[popup_index].H ? ext_certs[popup_index].H : ext_certs[popup_index].data" :fit="'cover'" />
    </q-card>
  </q-dialog>

  <q-dialog v-model="settings_dialog">
    <q-card>
      <q-card-section>
        <div class="h4 text-accent">{{ message[lang].mess_settings }}</div>
        <div class="h5 text-positive q-mt-sm">{{ message[lang].mess_settings_info }}</div>
      </q-card-section>

      <q-separator />

      <q-card-section style="max-height: 50vh" class="scroll">
        <q-option-group :options="message[lang].mess_settings_opt" type="checkbox" v-model="mess_settings_model" />
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn flat rounded :label="message[lang].save" color="primary" @click="mess_settings_save" />
        <q-btn flat rounded :label="message[lang].close" color="positive" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import dayjs from 'dayjs';
import {defineAsyncComponent, ref} from 'vue';
import {defineComponent} from 'vue';
// import AssessmentView from "./assessmentView.vue";
import {fabric} from 'fabric';
import {jsPDF} from 'jspdf';
// import VueApexCharts from "vue3-apexcharts"; //https://apexcharts.com/docs/
import Comments from './Comments.vue';
import {saveAs} from 'file-saver';
// import eventLk from './eventLk.vue';
import ipr from './IPR.vue';
// import {messageObj} from 'src/helpers/message';
import listOfEvents from 'src/ListOfEvents';
import listPrograms from 'src/ListOfPrograms';
import degree360ListLk from 'src/Degree360ListLK.vue';
import degree360List from 'src/Degree360List.vue';
import quiz from 'src/components/Quiz.vue';


import Message from 'src/components/Message.vue';

export default {
  props: {
    parent: Object,
    lang: String,
    back: String,
    tab_name: String,
    mess_settings: Object,
    app_mess_settings: Object,
  },
  emits: ['goback', 'testViewById', 'updSched', 'lessonViewById', 'courseViewById', 'mess_settings_save', 'setCountMyAss', 'setCountMyDegree360'],
  data: function () {
    return {
      addQuiz: false,
      addMessage: false,
      backScreen: 'StudentProfile',
      change_password_success: false,
      change_password_fail: false,
      passw_loading: false,
      is_change_password: false,
      password: '',
      password2: '',
      last_name: '',
      name: '',
      second_name: '',
      departments: [],
      work_position: '',
      selfItem: null,
      isShowDegree360List: true,
      isShowDegree360ListLk: true,
      loading_rating: true,
      max_file_size: 512000,
      debug: '',
      debug2: '',
      debug3: '',
      debug2_h: '',
      debug3_h: '',
      user_points_list: '',
      settings_dialog: ref(false),
      mess_settings_model: ref([]),
      userCoursesLoading: false,
      showChart: false,
      btnChart: false,
      legend: false,
      chartIndex: null,
      popup_cert: false,
      popup_index: 0,
      isCertsLoading: false,
      ext_certs: [],
      listFiles: [],
      listFilesForSave: [],
      listCompetence: [],
      showInd: [],
      table_fields: [],
      table_items: [],
      series: [],
      chartOptions: {
        chart: {
          height: 550,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },
        title: {text: ''},
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: [],
        },
      },
      loading_user_courses: ref(false),
      loading_user_tests: ref(false),
      scale: 100,
      canvas: '',
      isShowCert: ref(true),
      certificate: '',
      cert_opts_fio: false,
      cert_opts_pos: false,
      cert_opts_name: false,
      cert_opts_score: false,
      cert_opts_text: false,
      cert_text: '',
      width: 566,
      height: 800,
      my_cert: null,
      myCerts: '',
      myCerts_opts: '',
      loading: false,
      notifications: [],
      status_model: '',
      expanded: [],
      showTab: ref(false),
      splitterModel: ref(20),
      tab: ref('notifications'),
      // lang_model: ref(''),
      isShowChecked: false,
      scheduled_courses: '',
      scheduled_tests: '',
      item_DETAIL_TEXT_s: [],
      listOpens: '',
      lic_model: null,
      lic_to: '',
      lic_options: {
        ru: [
          {month: 0, name: 'Нет', count: 0},
          {month: 12, name: 'Безлимит 1 год', count: 100000},
          {month: 6, name: 'Безлимит 6 месяцев', count: 100000},
          {month: 3, name: 'Безлимит 3 месяца', count: 100000},
          {month: 1, name: 'Безлимит 1 месяцев', count: 100000},
          {month: 6, name: 'До 10 уроков/тестов/курсов, 6 месяцев', count: 10},
          {month: 3, name: 'До 10 уроков/тестов/курсов, 3 месяца', count: 10},
          {month: 1, name: 'До 10 уроков/тестов/курсов, 1 месяц', count: 10},
        ],
        en: [
          {month: 0, name: 'None', count: 0},
          {month: 12, name: 'Unlimited 1 year', count: 100000},
          {month: 6, name: 'Unlimited 6 months', count: 100000},
          {month: 3, name: 'Unlimited 3 months', count: 100000},
          {month: 1, name: 'Unlimited 1 month', count: 100000},
          {month: 6, name: 'Up to 10 lessons/tests/courses, 6 months', count: 10},
          {month: 3, name: 'Up to 10 lessons/tests/courses, 3 months', count: 10},
          {month: 1, name: 'Up to 10 lessons/tests/courses, 1 month', count: 10},
        ],
        ua: [
          {month: 0, name: 'Ні', count: 0},
          {month: 12, name: 'Безліміт 1 рік', count: 100000},
          {month: 6, name: 'Безліміт 6 місяців', count: 100000},
          {month: 3, name: 'Безліміт 3 місяці', count: 100000},
          {month: 1, name: 'Безліміт 1 місяць', count: 100000},
          {month: 6, name: 'До 10 уроків/тестів/курсів, 6 місяців', count: 10},
          {month: 3, name: 'До 10 уроків/тестів/курсів, 3 місяці', count: 10},
          {month: 1, name: 'До 10 уроків/тестів/курсів, 1 місяць', count: 10},
        ],
        fr: [
          {month: 0, name: 'Aucun', count: 0},
          {month: 12, name: 'Illimité 1 an', count: 100000},
          {month: 6, name: 'Illimité 6 mois', count: 100000},
          {month: 3, name: 'Illimité 3 mois', count: 100000},
          {month: 1, name: 'Illimité 1 mois', count: 100000},
          {month: 6, name: "Jusqu'à 10 leçons/tests/cours, 6 mois", count: 10},
          {month: 3, name: "Jusqu'à 10 leçons/tests/cours, 3 mois", count: 10},
          {month: 1, name: "Jusqu'à 10 leçons/tests/cours, 1 mois", count: 10},
        ],
        de: [
          {month: 0, name: 'Keine', count: 0},
          {month: 12, name: 'Unbegrenzt 1 Jahr', count: 100000},
          {month: 6, name: 'Unbegrenzt 6 Monate', count: 100000},
          {month: 3, name: 'Unbegrenzt 3 Monate', count: 100000},
          {month: 1, name: 'Unbegrenzt 1 Monat', count: 100000},
          {month: 6, name: 'Bis zu 10 Lektionen/Tests/Kurse, 6 Monate', count: 10},
          {month: 3, name: 'Bis zu 10 Lektionen/Tests/Kurse, 3 Monate', count: 10},
          {month: 1, name: 'Bis zu 10 Lektionen/Tests/Kurse, 1 Monat', count: 10},
        ],
        it: [
          {month: 0, name: 'Nessuno', count: 0},
          {month: 12, name: 'Illimitato 1 anno', count: 100000},
          {month: 6, name: 'Illimitato 6 mesi', count: 100000},
          {month: 3, name: 'Illimitato 3 mesi', count: 100000},
          {month: 1, name: 'Illimitato 1 mese', count: 100000},
          {month: 6, name: 'Fino a 10 lezioni/test/corsi, 6 mesi', count: 10},
          {month: 3, name: 'Fino a 10 lezioni/test/corsi, 3 mesi', count: 10},
          {month: 1, name: 'Fino a 10 lezioni/test/corsi, 1 mese', count: 10},
        ],
        tr: [
          {month: 0, name: 'Yok', count: 0},
          {month: 12, name: 'Sınırsız 1 yıl', count: 100000},
          {month: 6, name: 'Sınırsız 6 ay', count: 100000},
          {month: 3, name: 'Sınırsız 3 ay', count: 100000},
          {month: 1, name: 'Sınırsız 1 ay', count: 100000},
          {month: 6, name: '10 derse/teste/kursa kadar, 6 ay', count: 10},
          {month: 3, name: '10 derse/teste/kursa kadar, 3 ay', count: 10},
          {month: 1, name: '10 derse/teste/kursa kadar, 1 ay', count: 10},
        ],
        pl: [
          {month: 0, name: 'Brak', count: 0},
          {month: 12, name: 'Bez limitu 1 rok', count: 100000},
          {month: 6, name: 'Bez limitu 6 miesięcy', count: 100000},
          {month: 3, name: 'Bez limitu 3 miesiące', count: 100000},
          {month: 1, name: 'Bez limitu 1 miesiąc', count: 100000},
          {month: 6, name: 'Do 10 lekcji/testów/kursów, 6 miesięcy', count: 10},
          {month: 3, name: 'Do 10 lekcji/testów/kursów, 3 miesiące', count: 10},
          {month: 1, name: 'Do 10 lekcji/testów/kursów, 1 miesiąc', count: 10},
        ],
        pt: [
          {month: 0, name: 'Nenhum', count: 0},
          {month: 12, name: 'Ilimitado 1 ano', count: 100000},
          {month: 6, name: 'Ilimitado 6 meses', count: 100000},
          {month: 3, name: 'Ilimitado 3 meses', count: 100000},
          {month: 1, name: 'Ilimitado 1 mês', count: 100000},
          {month: 6, name: 'Até 10 aulas/testes/cursos, 6 meses', count: 10},
          {month: 3, name: 'Até 10 aulas/testes/cursos, 3 meses', count: 10},
          {month: 1, name: 'Até 10 aulas/testes/cursos, 1 mês', count: 10},
        ],
        es: [
          {month: 0, name: 'Ninguno', count: 0},
          {month: 12, name: 'Ilimitado 1 año', count: 100000},
          {month: 6, name: 'Ilimitado 6 meses', count: 100000},
          {month: 3, name: 'Ilimitado 3 meses', count: 100000},
          {month: 1, name: 'Ilimitado 1 mes', count: 100000},
          {month: 6, name: 'Hasta 10 lecciones/pruebas/cursos, 6 meses', count: 10},
          {month: 3, name: 'Hasta 10 lecciones/pruebas/cursos, 3 meses', count: 10},
          {month: 1, name: 'Hasta 10 lecciones/pruebas/cursos, 1 mes', count: 10},
        ],
        vn: [
          {month: 0, name: 'Không', count: 0},
          {month: 12, name: 'Không giới hạn 1 năm', count: 100000},
          {month: 6, name: 'Không giới hạn 6 tháng', count: 100000},
          {month: 3, name: 'Không giới hạn 3 tháng', count: 100000},
          {month: 1, name: 'Không giới hạn 1 tháng', count: 100000},
          {month: 6, name: 'Tối đa 10 bài học/kiểm tra/khóa học, 6 tháng', count: 10},
          {month: 3, name: 'Tối đa 10 bài học/kiểm tra/khóa học, 3 tháng', count: 10},
          {month: 1, name: 'Tối đa 10 bài học/kiểm tra/khóa học, 1 tháng', count: 10},
        ],
      },
      disk_model: null,
      disk_to: '',
      disk_options: {
        ru: [
          {month: 0, name: 'Нет', count: 0},
          {month: 12, name: 'До 2Гб места в облаке на 1 год', mb: 1024 * 2},
          {month: 12, name: 'До 4Гб места в облаке на 1 год', mb: 1024 * 4},
          {month: 12, name: 'До 8Гб места в облаке на 1 год', mb: 1024 * 8},
          {month: 12, name: 'До 16Гб места в облаке на 1 год', mb: 1024 * 16},
          {month: 12, name: 'До 32Гб места в облаке на 1 год', mb: 1024 * 32},
          {month: 12, name: 'До 64Гб места в облаке на 1 год', mb: 1024 * 64},
          {month: 12, name: 'До 128Гб места в облаке на 1 год', mb: 1024 * 128},
          {month: 12, name: 'До 256Гб места в облаке на 1 год', mb: 1024 * 256},
          {month: 12, name: 'До 512Гб места в облаке на 1 год', mb: 1024 * 512},
          {month: 12, name: 'До 1024Гб места в облаке на 1 год', mb: 1024 * 1024},
          {month: 12, name: 'До 2048Гб места в облаке на 1 год', mb: 1024 * 2048},
        ],
        en: [
          {month: 0, name: 'None', count: 0},
          {month: 12, name: 'Up to 2GB cloud storage for 1 year', mb: 1024 * 2},
          {month: 12, name: 'Up to 4GB cloud storage for 1 year', mb: 1024 * 4},
          {month: 12, name: 'Up to 8GB cloud storage for 1 year', mb: 1024 * 8},
          {month: 12, name: 'Up to 16GB cloud storage for 1 year', mb: 1024 * 16},
          {month: 12, name: 'Up to 32GB cloud storage for 1 year', mb: 1024 * 32},
          {month: 12, name: 'Up to 64GB cloud storage for 1 year', mb: 1024 * 64},
          {month: 12, name: 'Up to 128GB cloud storage for 1 year', mb: 1024 * 128},
          {month: 12, name: 'Up to 256GB cloud storage for 1 year', mb: 1024 * 256},
          {month: 12, name: 'Up to 512GB cloud storage for 1 year', mb: 1024 * 512},
          {month: 12, name: 'Up to 1024GB cloud storage for 1 year', mb: 1024 * 1024},
          {month: 12, name: 'Up to 2048GB cloud storage for 1 year', mb: 1024 * 2048},
        ],
        ua: [
          {month: 0, name: 'Ні', count: 0},
          {month: 12, name: 'До 2Гб місця в хмарі на 1 рік', mb: 1024 * 2},
          {month: 12, name: 'До 4Гб місця в хмарі на 1 рік', mb: 1024 * 4},
          {month: 12, name: 'До 8Гб місця в хмарі на 1 рік', mb: 1024 * 8},
          {month: 12, name: 'До 16Гб місця в хмарі на 1 рік', mb: 1024 * 16},
          {month: 12, name: 'До 32Гб місця в хмарі на 1 рік', mb: 1024 * 32},
          {month: 12, name: 'До 64Гб місця в хмарі на 1 рік', mb: 1024 * 64},
          {month: 12, name: 'До 128Гб місця в хмарі на 1 рік', mb: 1024 * 128},
          {month: 12, name: 'До 256Гб місця в хмарі на 1 рік', mb: 1024 * 256},
          {month: 12, name: 'До 512Гб місця в хмарі на 1 рік', mb: 1024 * 512},
          {month: 12, name: 'До 1024Гб місця в хмарі на 1 рік', mb: 1024 * 1024},
          {month: 12, name: 'До 2048Гб місця в хмарі на 1 рік', mb: 1024 * 2048},
        ],
        fr: [
          {month: 0, name: 'Aucun', count: 0},
          {month: 12, name: "Jusqu'à 2 Go de stockage cloud pour 1 an", mb: 1024 * 2},
          {month: 12, name: "Jusqu'à 4 Go de stockage cloud pour 1 an", mb: 1024 * 4},
          {month: 12, name: "Jusqu'à 8 Go de stockage cloud pour 1 an", mb: 1024 * 8},
          {month: 12, name: "Jusqu'à 16 Go de stockage cloud pour 1 an", mb: 1024 * 16},
          {month: 12, name: "Jusqu'à 32 Go de stockage cloud pour 1 an", mb: 1024 * 32},
          {month: 12, name: "Jusqu'à 64 Go de stockage cloud pour 1 an", mb: 1024 * 64},
          {month: 12, name: "Jusqu'à 128 Go de stockage cloud pour 1 an", mb: 1024 * 128},
          {month: 12, name: "Jusqu'à 256 Go de stockage cloud pour 1 an", mb: 1024 * 256},
          {month: 12, name: "Jusqu'à 512 Go de stockage cloud pour 1 an", mb: 1024 * 512},
          {month: 12, name: "Jusqu'à 1024 Go de stockage cloud pour 1 an", mb: 1024 * 1024},
          {month: 12, name: "Jusqu'à 2048 Go de stockage cloud pour 1 an", mb: 1024 * 2048},
        ],
        de: [
          {month: 0, name: 'Keine', count: 0},
          {month: 12, name: 'Bis zu 2GB Cloud-Speicher für 1 Jahr', mb: 1024 * 2},
          {month: 12, name: 'Bis zu 4GB Cloud-Speicher für 1 Jahr', mb: 1024 * 4},
          {month: 12, name: 'Bis zu 8GB Cloud-Speicher für 1 Jahr', mb: 1024 * 8},
          {month: 12, name: 'Bis zu 16GB Cloud-Speicher für 1 Jahr', mb: 1024 * 16},
          {month: 12, name: 'Bis zu 32GB Cloud-Speicher für 1 Jahr', mb: 1024 * 32},
          {month: 12, name: 'Bis zu 64GB Cloud-Speicher für 1 Jahr', mb: 1024 * 64},
          {month: 12, name: 'Bis zu 128GB Cloud-Speicher für 1 Jahr', mb: 1024 * 128},
          {month: 12, name: 'Bis zu 256GB Cloud-Speicher für 1 Jahr', mb: 1024 * 256},
          {month: 12, name: 'Bis zu 512GB Cloud-Speicher für 1 Jahr', mb: 1024 * 512},
          {month: 12, name: 'Bis zu 1024GB Cloud-Speicher für 1 Jahr', mb: 1024 * 1024},
          {month: 12, name: 'Bis zu 2048GB Cloud-Speicher für 1 Jahr', mb: 1024 * 2048},
        ],
        it: [
          {month: 0, name: 'Nessuno', count: 0},
          {month: 12, name: 'Fino a 2GB di spazio cloud per 1 anno', mb: 1024 * 2},
          {month: 12, name: 'Fino a 4GB di spazio cloud per 1 anno', mb: 1024 * 4},
          {month: 12, name: 'Fino a 8GB di spazio cloud per 1 anno', mb: 1024 * 8},
          {month: 12, name: 'Fino a 16GB di spazio cloud per 1 anno', mb: 1024 * 16},
          {month: 12, name: 'Fino a 32GB di spazio cloud per 1 anno', mb: 1024 * 32},
          {month: 12, name: 'Fino a 64GB di spazio cloud per 1 anno', mb: 1024 * 64},
          {month: 12, name: 'Fino a 128GB di spazio cloud per 1 anno', mb: 1024 * 128},
          {month: 12, name: 'Fino a 256GB di spazio cloud per 1 anno', mb: 1024 * 256},
          {month: 12, name: 'Fino a 512GB di spazio cloud per 1 anno', mb: 1024 * 512},
          {month: 12, name: 'Fino a 1024GB di spazio cloud per 1 anno', mb: 1024 * 1024},
          {month: 12, name: 'Fino a 2048GB di spazio cloud per 1 anno', mb: 1024 * 2048},
        ],
        tr: [
          {month: 0, name: 'Yok', count: 0},
          {month: 12, name: "1 yıl için 2GB'a kadar bulut depolama", mb: 1024 * 2},
          {month: 12, name: "1 yıl için 4GB'a kadar bulut depolama", mb: 1024 * 4},
          {month: 12, name: "1 yıl için 8GB'a kadar bulut depolama", mb: 1024 * 8},
          {month: 12, name: "1 yıl için 16GB'a kadar bulut depolama", mb: 1024 * 16},
          {month: 12, name: "1 yıl için 32GB'a kadar bulut depolama", mb: 1024 * 32},
          {month: 12, name: "1 yıl için 64GB'a kadar bulut depolama", mb: 1024 * 64},
          {month: 12, name: "1 yıl için 128GB'a kadar bulut depolama", mb: 1024 * 128},
          {month: 12, name: "1 yıl için 256GB'a kadar bulut depolama", mb: 1024 * 256},
          {month: 12, name: "1 yıl için 512GB'a kadar bulut depolama", mb: 1024 * 512},
          {month: 12, name: "1 yıl için 1024GB'a kadar bulut depolama", mb: 1024 * 1024},
          {month: 12, name: "1 yıl için 2048GB'a kadar bulut depolama", mb: 1024 * 2048},
        ],
        pl: [
          {month: 0, name: 'Brak', count: 0},
          {month: 12, name: 'Do 2GB miejsca w chmurze na 1 rok', mb: 1024 * 2},
          {month: 12, name: 'Do 4GB miejsca w chmurze na 1 rok', mb: 1024 * 4},
          {month: 12, name: 'Do 8GB miejsca w chmurze na 1 rok', mb: 1024 * 8},
          {month: 12, name: 'Do 16GB miejsca w chmurze na 1 rok', mb: 1024 * 16},
          {month: 12, name: 'Do 32GB miejsca w chmurze na 1 rok', mb: 1024 * 32},
          {month: 12, name: 'Do 64GB miejsca w chmurze na 1 rok', mb: 1024 * 64},
          {month: 12, name: 'Do 128GB miejsca w chmurze na 1 rok', mb: 1024 * 128},
          {month: 12, name: 'Do 256GB miejsca w chmurze na 1 rok', mb: 1024 * 256},
          {month: 12, name: 'Do 512GB miejsca w chmurze na 1 rok', mb: 1024 * 512},
          {month: 12, name: 'Do 1024GB miejsca w chmurze na 1 rok', mb: 1024 * 1024},
          {month: 12, name: 'Do 2048GB miejsca w chmurze na 1 rok', mb: 1024 * 2048},
        ],
        pt: [
          {month: 0, name: 'Nenhum', count: 0},
          {month: 12, name: 'Até 2GB de armazenamento em nuvem por 1 ano', mb: 1024 * 2},
          {month: 12, name: 'Até 4GB de armazenamento em nuvem por 1 ano', mb: 1024 * 4},
          {month: 12, name: 'Até 8GB de armazenamento em nuvem por 1 ano', mb: 1024 * 8},
          {month: 12, name: 'Até 16GB de armazenamento em nuvem por 1 ano', mb: 1024 * 16},
          {month: 12, name: 'Até 32GB de armazenamento em nuvem por 1 ano', mb: 1024 * 32},
          {month: 12, name: 'Até 64GB de armazenamento em nuvem por 1 ano', mb: 1024 * 64},
          {month: 12, name: 'Até 128GB de armazenamento em nuvem por 1 ano', mb: 1024 * 128},
          {month: 12, name: 'Até 256GB de armazenamento em nuvem por 1 ano', mb: 1024 * 256},
          {month: 12, name: 'Até 512GB de armazenamento em nuvem por 1 ano', mb: 1024 * 512},
          {month: 12, name: 'Até 1024GB de armazenamento em nuvem por 1 ano', mb: 1024 * 1024},
          {month: 12, name: 'Até 2048GB de armazenamento em nuvem por 1 ano', mb: 1024 * 2048},
        ],
        es: [
          {month: 0, name: 'Ninguno', count: 0},
          {month: 12, name: 'Hasta 2GB de almacenamiento en la nube por 1 año', mb: 1024 * 2},
          {month: 12, name: 'Hasta 4GB de almacenamiento en la nube por 1 año', mb: 1024 * 4},
          {month: 12, name: 'Hasta 8GB de almacenamiento en la nube por 1 año', mb: 1024 * 8},
          {month: 12, name: 'Hasta 16GB de almacenamiento en la nube por 1 año', mb: 1024 * 16},
          {month: 12, name: 'Hasta 32GB de almacenamiento en la nube por 1 año', mb: 1024 * 32},
          {month: 12, name: 'Hasta 64GB de almacenamiento en la nube por 1 año', mb: 1024 * 64},
          {month: 12, name: 'Hasta 128GB de almacenamiento en la nube por 1 año', mb: 1024 * 128},
          {month: 12, name: 'Hasta 256GB de almacenamiento en la nube por 1 año', mb: 1024 * 256},
          {month: 12, name: 'Hasta 512GB de almacenamiento en la nube por 1 año', mb: 1024 * 512},
          {month: 12, name: 'Hasta 1024GB de almacenamiento en la nube por 1 año', mb: 1024 * 1024},
          {month: 12, name: 'Hasta 2048GB de almacenamiento en la nube por 1 año', mb: 1024 * 2048},
        ],
        vn: [
          {month: 0, name: 'Không', count: 0},
          {month: 12, name: 'Lên đến 2GB lưu trữ đám mây trong 1 năm', mb: 1024 * 2},
          {month: 12, name: 'Lên đến 4GB lưu trữ đám mây trong 1 năm', mb: 1024 * 4},
          {month: 12, name: 'Lên đến 8GB lưu trữ đám mây trong 1 năm', mb: 1024 * 8},
          {month: 12, name: 'Lên đến 16GB lưu trữ đám mây trong 1 năm', mb: 1024 * 16},
          {month: 12, name: 'Lên đến 32GB lưu trữ đám mây trong 1 năm', mb: 1024 * 32},
          {month: 12, name: 'Lên đến 64GB lưu trữ đám mây trong 1 năm', mb: 1024 * 64},
          {month: 12, name: 'Lên đến 128GB lưu trữ đám mây trong 1 năm', mb: 1024 * 128},
          {month: 12, name: 'Lên đến 256GB lưu trữ đám mây trong 1 năm', mb: 1024 * 256},
          {month: 12, name: 'Lên đến 512GB lưu trữ đám mây trong 1 năm', mb: 1024 * 512},
          {month: 12, name: 'Lên đến 1024GB lưu trữ đám mây trong 1 năm', mb: 1024 * 1024},
          {month: 12, name: 'Lên đến 2048GB lưu trữ đám mây trong 1 năm', mb: 1024 * 2048},
        ],
      },
      theme_model: null,
      theme_to: '',
      theme_options: {
        ru: [
          {month: 0, name: 'Нет', count: 0},
          {month: 12, name: 'Настройка темы на 1 год', count: 1},
        ],
        en: [
          {month: 0, name: 'None', count: 0},
          {month: 12, name: 'Theme customization for 1 year', count: 1},
        ],
        ua: [
          {month: 0, name: 'Ні', count: 0},
          {month: 12, name: 'Налаштування теми на 1 рік', count: 1},
        ],
        fr: [
          {month: 0, name: 'Aucun', count: 0},
          {month: 12, name: 'Personnalisation du thème pour 1 an', count: 1},
        ],
        de: [
          {month: 0, name: 'Keine', count: 0},
          {month: 12, name: 'Themenanpassung für 1 Jahr', count: 1},
        ],
        it: [
          {month: 0, name: 'Nessuno', count: 0},
          {month: 12, name: 'Personalizzazione del tema per 1 anno', count: 1},
        ],
        tr: [
          {month: 0, name: 'Yok', count: 0},
          {month: 12, name: '1 yıl için tema özelleştirme', count: 1},
        ],
        pl: [
          {month: 0, name: 'Brak', count: 0},
          {month: 12, name: 'Dostosowanie motywu na 1 rok', count: 1},
        ],
        pt: [
          {month: 0, name: 'Nenhum', count: 0},
          {month: 12, name: 'Personalização de tema por 1 ano', count: 1},
        ],
        es: [
          {month: 0, name: 'Ninguno', count: 0},
          {month: 12, name: 'Personalización de tema por 1 año', count: 1},
        ],
        vn: [
          {month: 0, name: 'Không', count: 0},
          {month: 12, name: 'Tùy chỉnh chủ đề trong 1 năm', count: 1},
        ],
      },
      subac_model: null,
      subac_to: '',
      subac_options: {
        ru: [
          {month: 0, name: 'Нет', count: 0},
          {month: 1, name: 'ДЕМО. Обучение под одной учеткой на 1 месяц до 100 человек', count: 100},
          {month: 12, name: 'Обучение под одной учеткой на 1 год до 50 человек', count: 50},
          {month: 12, name: 'Обучение под одной учеткой на 1 год до 100 человек', count: 100},
          {month: 12, name: 'Обучение под одной учеткой на 1 год до 500 человек', count: 500},
          {month: 12, name: 'Обучение под одной учеткой на 1 год до 1000 человек', count: 1000},
          {month: 12, name: 'Обучение под одной учеткой на 1 год до 10000 человек', count: 10000},
        ],
        en: [
          {month: 0, name: 'None', count: 0},
          {month: 1, name: 'DEMO. Training under one account for 1 month up to 100 people', count: 100},
          {month: 12, name: 'Training under one account for 1 year up to 50 people', count: 50},
          {month: 12, name: 'Training under one account for 1 year up to 100 people', count: 100},
          {month: 12, name: 'Training under one account for 1 year up to 500 people', count: 500},
          {month: 12, name: 'Training under one account for 1 year up to 1000 people', count: 1000},
          {month: 12, name: 'Training under one account for 1 year up to 10000 people', count: 10000},
        ],
        ua: [
          {month: 0, name: 'Ні', count: 0},
          {month: 1, name: 'ДЕМО. Навчання під одним обліковим записом на 1 місяць до 100 осіб', count: 100},
          {month: 12, name: 'Навчання під одним обліковим записом на 1 рік до 50 осіб', count: 50},
          {month: 12, name: 'Навчання під одним обліковим записом на 1 рік до 100 осіб', count: 100},
          {month: 12, name: 'Навчання під одним обліковим записом на 1 рік до 500 осіб', count: 500},
          {month: 12, name: 'Навчання під одним обліковим записом на 1 рік до 1000 осіб', count: 1000},
          {month: 12, name: 'Навчання під одним обліковим записом на 1 рік до 10000 осіб', count: 10000},
        ],
        fr: [
          {month: 0, name: 'Aucun', count: 0},
          {month: 1, name: "DÉMO. Formation sous un compte pour 1 mois jusqu'à 100 personnes", count: 100},
          {month: 12, name: "Formation sous un compte pour 1 an jusqu'à 50 personnes", count: 50},
          {month: 12, name: "Formation sous un compte pour 1 an jusqu'à 100 personnes", count: 100},
          {month: 12, name: "Formation sous un compte pour 1 an jusqu'à 500 personnes", count: 500},
          {month: 12, name: "Formation sous un compte pour 1 an jusqu'à 1000 personnes", count: 1000},
          {month: 12, name: "Formation sous un compte pour 1 an jusqu'à 10000 personnes", count: 10000},
        ],
        de: [
          {month: 0, name: 'Keine', count: 0},
          {month: 1, name: 'DEMO. Schulung unter einem Konto für 1 Monat bis zu 100 Personen', count: 100},
          {month: 12, name: 'Schulung unter einem Konto für 1 Jahr bis zu 50 Personen', count: 50},
          {month: 12, name: 'Schulung unter einem Konto für 1 Jahr bis zu 100 Personen', count: 100},
          {month: 12, name: 'Schulung unter einem Konto für 1 Jahr bis zu 500 Personen', count: 500},
          {month: 12, name: 'Schulung unter einem Konto für 1 Jahr bis zu 1000 Personen', count: 1000},
          {month: 12, name: 'Schulung unter einem Konto für 1 Jahr bis zu 10000 Personen', count: 10000},
        ],
        it: [
          {month: 0, name: 'Nessuno', count: 0},
          {month: 1, name: 'DEMO. Formazione sotto un account per 1 mese fino a 100 persone', count: 100},
          {month: 12, name: 'Formazione sotto un account per 1 anno fino a 50 persone', count: 50},
          {month: 12, name: 'Formazione sotto un account per 1 anno fino a 100 persone', count: 100},
          {month: 12, name: 'Formazione sotto un account per 1 anno fino a 500 persone', count: 500},
          {month: 12, name: 'Formazione sotto un account per 1 anno fino a 1000 persone', count: 1000},
          {month: 12, name: 'Formazione sotto un account per 1 anno fino a 10000 persone', count: 10000},
        ],
        tr: [
          {month: 0, name: 'Yok', count: 0},
          {month: 1, name: 'DEMO. 1 ay boyunca 100 kişiye kadar tek hesap altında eğitim', count: 100},
          {month: 12, name: '1 yıl boyunca 50 kişiye kadar tek hesap altında eğitim', count: 50},
          {month: 12, name: '1 yıl boyunca 100 kişiye kadar tek hesap altında eğitim', count: 100},
          {month: 12, name: '1 yıl boyunca 500 kişiye kadar tek hesap altında eğitim', count: 500},
          {month: 12, name: '1 yıl boyunca 1000 kişiye kadar tek hesap altında eğitim', count: 1000},
          {month: 12, name: '1 yıl boyunca 10000 kişiye kadar tek hesap altında eğitim', count: 10000},
        ],
        pl: [
          {month: 0, name: 'Brak', count: 0},
          {month: 1, name: 'DEMO. Szkolenie na jednym koncie przez 1 miesiąc do 100 osób', count: 100},
          {month: 12, name: 'Szkolenie na jednym koncie przez 1 rok do 50 osób', count: 50},
          {month: 12, name: 'Szkolenie na jednym koncie przez 1 rok do 100 osób', count: 100},
          {month: 12, name: 'Szkolenie na jednym koncie przez 1 rok do 500 osób', count: 500},
          {month: 12, name: 'Szkolenie na jednym koncie przez 1 rok do 1000 osób', count: 1000},
          {month: 12, name: 'Szkolenie na jednym koncie przez 1 rok do 10000 osób', count: 10000},
        ],
        pt: [
          {month: 0, name: 'Nenhum', count: 0},
          {month: 1, name: 'DEMO. Treinamento em uma conta por 1 mês para até 100 pessoas', count: 100},
          {month: 12, name: 'Treinamento em uma conta por 1 ano para até 50 pessoas', count: 50},
          {month: 12, name: 'Treinamento em uma conta por 1 ano para até 100 pessoas', count: 100},
          {month: 12, name: 'Treinamento em uma conta por 1 ano para até 500 pessoas', count: 500},
          {month: 12, name: 'Treinamento em uma conta por 1 ano para até 1000 pessoas', count: 1000},
          {month: 12, name: 'Treinamento em uma conta por 1 ano para até 10000 pessoas', count: 10000},
        ],
        es: [
          {month: 0, name: 'Ninguno', count: 0},
          {month: 1, name: 'DEMO. Capacitación bajo una cuenta por 1 mes hasta 100 personas', count: 100},
          {month: 12, name: 'Capacitación bajo una cuenta por 1 año hasta 50 personas', count: 50},
          {month: 12, name: 'Capacitación bajo una cuenta por 1 año hasta 100 personas', count: 100},
          {month: 12, name: 'Capacitación bajo una cuenta por 1 año hasta 500 personas', count: 500},
          {month: 12, name: 'Capacitación bajo una cuenta por 1 año hasta 1000 personas', count: 1000},
          {month: 12, name: 'Capacitación bajo una cuenta por 1 año hasta 10000 personas', count: 10000},
        ],
        vn: [
          {month: 0, name: 'Không', count: 0},
          {month: 1, name: 'DEMO. Đào tạo dưới một tài khoản trong 1 tháng lên đến 100 người', count: 100},
          {month: 12, name: 'Đào tạo dưới một tài khoản trong 1 năm lên đến 50 người', count: 50},
          {month: 12, name: 'Đào tạo dưới một tài khoản trong 1 năm lên đến 100 người', count: 100},
          {month: 12, name: 'Đào tạo dưới một tài khoản trong 1 năm lên đến 500 người', count: 500},
          {month: 12, name: 'Đào tạo dưới một tài khoản trong 1 năm lên đến 1000 người', count: 1000},
          {month: 12, name: 'Đào tạo dưới một tài khoản trong 1 năm lên đến 10000 người', count: 10000},
        ],
      },
      key_str: '',

      status_options: {
        ru: [
          {value: '', text: '- Выбрать -', disabled: false},
          {value: 'Y', text: 'Правильно'},
          {value: 'N', text: 'Неправильно'},
        ],
        en: [
          {value: '', text: '- Select -', disabled: false},
          {value: 'Y', text: "That's right"},
          {value: 'N', text: 'Incorrect'},
        ],
        ua: [
          {value: '', text: ' - вибрати -', disabled: false},
          {value: 'Y', text: 'Правильно'},
          {value: 'N', text: 'Неправильно'},
        ],
        fr: [
          {value: '', text: '- Sélectionner -', disabled: false},
          {value: 'Y', text: 'Juste'},
          {value: 'N', text: 'Erroné'},
        ],
        it: [
          {value: '', text: '- Seleziona -', disabled: false},
          {value: 'Y', text: 'Corretto'},
          {value: 'N', text: 'Sbagliato'},
        ],
        tr: [
          {value: '', text: '- Seçme -', disabled: false},
          {value: 'Y', text: 'Doğru'},
          {value: 'N', text: 'Yanlış'},
        ],
        de: [
          {value: '', text: '- Auswählen -', disabled: false},
          {value: 'Y', text: 'Korrekt'},
          {value: 'N', text: 'Falsch'},
        ],
        pl: [
          {value: '', text: '- Wybierz -', disabled: false},
          {value: 'Y', text: 'Zgadza się'},
          {value: 'N', text: 'Błędny'},
        ],
        pt: [
          {value: '', text: '- Select -', disabled: false},
          {value: 'Y', text: "That's right"},
          {value: 'N', text: 'Incorrect'},
        ],
        es: [
          {value: '', text: '- Seleccione -', disabled: false},
          {value: 'Y', text: 'Eso es correcto'},
          {value: 'N', text: 'Incorrecto'},
        ],
        vn: [
          {value: '', text: '- Lựa chọn -', disabled: false},
          {value: 'Y', text: 'Direito'},
          {value: 'N', text: 'Não propriamente'},
        ],
      },
      active: '',
      showCollapse: [],
      showAssesments: true,
      showAss: [],
      goback: '',
      today: '',
      collapse: [],
      isShowCourses: true,
      profile: null,
      experience: '',
      education: '',
      user_points: -1,
      isLoading: false,
      user_tests: [],
      user_courses: [],
      accord_row: 'accord_row',
      accord_row2: 'accord_row2',
      textgreen: 'text-green',
      textred: 'text-red',
      userGroups: [],
      assessmentList: [],
      assesment: '',
      
      message: this.parent.dictionary,
      messageObj: this.parent.dictionary,
    };
  },
  mounted: function () {
    let $this = this;
    $this.parent.isLoadingModule = false;
    this.mess_settings_model = this.mess_settings;
    // console.log('::mounted StudentProfile tab_name=', this.tab_name, this.lang, this.parent.lang_options);
    // console.log('::mounted message=',{...this.message});
    // console.log('::mounted parent.dictionary=',{...this.parent.dictionary});
    this.tab = this.tab_name;

    this.splitterModel = this.parent.isMobile ? 5 : 20;
    // let lang_model = this.parent.lang_options.find((item) => {
    //   return item.value == this.lang;
    // });
    // if (lang_model) {
    //   this.lang_model = lang_model.text;
    // } else {
    //   this.lang_model = $this.lang_options[0].text;
    // }

    $this.parent.checkPulse('LK').then(
      (res) => {
        if (this.tab_name === 'notifications') {
          $this.loading = true;
          let notifications = localStorage.getItem('notifications');
          try{
            if(notifications){
              $this.notifications = JSON.parse(notifications);  
              console.log('::notifications loaded from local');
               $this.loading = false;
            }
          }catch(e){
            console.log(e);
          }
          
          this.loadNotifications().then((res_notes) => {
            this.parent.loadQizList().then((res_quiz) => {
              // console.log(':: loadQizList res_quiz=',res_quiz)
                
              $this.parent.loadQizList(null, 'uni_message').then((res_mess) => {
                let mergedArray = $this.parent.mergeArrays(res_quiz, res_mess);
                $this.notifications = $this.parent.mergeArrays(res_notes, mergedArray);
                localStorage.setItem('notifications', JSON.stringify($this.notifications))
                // console.log(':: notifications=',$this.notifications)
                $this.loading = false;
              });

            });
          });
        }

        $this.checkCoursesAndTests();

        $this.getListGroups();

        this.assessmentList = [];
        // this.loadMyAssessments("experts").then((res) => {
        //   this.loadMyAssessments("persona");
        // });

        this.countMyAssBackground();

        this.parent.getProfileByUserId(this.parent.user_current.ID).then(
          (profile) => {
            $this.parent.getUserPoints(profile).then((res) => {
              $this.loading_rating = false;
              $this.user_points_list = res;
              // console.log(":: $this.user_points_list=", $this.user_points_list);
            });
            $this.profile = profile;
            // console.log('::StudentProfile profile=', $this.profile);
            this.name = $this.parent.restoreQuotes(profile.PROPERTY_VALUES.name);
            this.second_name = $this.parent.restoreQuotes(profile.PROPERTY_VALUES.second_name);
            this.last_name = $this.parent.restoreQuotes(profile.PROPERTY_VALUES.last_name);
            this.work_position = $this.parent.restoreQuotes(profile.PROPERTY_VALUES.work_position);
            // this.departments = $this.parent.restoreQuotes(profile.PROPERTY_VALUES.departments);

            $this.experience = $this.parent.restoreQuotes(profile.PREVIEW_TEXT);
            $this.education = $this.parent.restoreQuotes(profile.DETAIL_TEXT);
            $this.user_points = $this.parent.sumUserPoints(profile.PROPERTY_VALUES.points);
            if ($this.parent.isJSON(profile.PROPERTY_VALUES.certs)) {
              $this.ext_certs = JSON.parse(profile.PROPERTY_VALUES.certs);
            }
            //устарело оставим для совместимости
            this.isCertsLoading = true;
            this.loadCerts().then((res) => {
              // console.log(res, this.ext_certs);
              this.isCertsLoading = false;
            });
            // console.log('::StudentProfile =', $this.profile);
          },
          (error) => {
            console.log(error);
            if (error === 'profileNotFound') {
              $this.saveProfile();
            }
          }
        );

        this.parent.competenceGetList('profile', false, this.parent.user_current.ID, null, null, this.parent.user_current.ID).then((res) => {
          // console.log('::StudentProfile profiles_on_appr=', res);
          this.listCompetence = res;
          this.showInd = Array(this.listCompetence.length).fill(false);
        });

        this.loadOpensLIst();

        this.goback = this.back ? this.back : 'coursesList';
        this.today = dayjs().format('YYYYMMDD');

        if (this.parent.isModer && this.tab_name === 'notifications') {
          if(this.message[this.lang].mess_settings_opt && !this.message[this.lang].mess_settings_opt.find(item => item.value === 'self')){
            this.message[this.lang].mess_settings_opt = [...this.message[this.lang].mess_settings_opt, ...this.message[this.lang].mess_settings_opt_moder];
          }


          // console.log("::mess_settings_opt=", this.message[this.lang].mess_settings_opt);
          // console.log("::app_mess_settings=", this.app_mess_settings);
          // console.log("::mess_settings_model=", this.mess_settings_model);
          for (let key in this.app_mess_settings) {
            if (this.app_mess_settings[key] !== null) {
              const index = this.message[this.lang].mess_settings_opt.findIndex((item) => item.value === key);
              if (index >= 0) {
                this.message[this.lang].mess_settings_opt[index].disable = true;
              }
              if (this.app_mess_settings[key] && !this.mess_settings_model.includes(key)) {
                this.mess_settings_model.push(key);
              } else {
                this.mess_settings_model = this.mess_settings_model.filter((item) => item !== key);
              }
            }
          }

          // Виджет ОЛ
          // console.log('::Виджет ОЛ appRegion=',this.parent.appRegion)
          (function (w, d, u) {
            var s = d.createElement('script');
            s.async = true;
            s.src = u + '?' + ((Date.now() / 60000) | 0);
            var h = d.getElementsByTagName('script')[0];
            h.parentNode.insertBefore(s, h);
          })(window, document, 'https://cdn-ru.bitrix24.ru/b13945802/crm/site_button/loader_2_kn4idd.js');
        }
      },
      (err) => {}
    );
  },
  watch: {
    my_cert(value) {
      // console.log('::my_cert value=', value);
      this.parent.getCertAndList(value.cert_id).then((res) => {
        if (res) {
          // this.cert_list = res.list;
          this.certificate = res.cert;
          this.initCanvas().then((res) => {
            this.showCert(value);
          });
        }
      });
    },
    // lang_model: function (arg) {
    //   let $this=this;
    //   // console.log('::lang_model= ', arg, this.lang_model);
    //   const old_lang = this.parent.lang;
    //   if (this.lang_model) {
    //     let lang = this.parent.getValueByText(this.lang_model, this.parent.lang_options);
    //     // console.log('::lang= ', lang, this.parent.lang_options);

    //     if (!this.parent.categories || typeof this.parent.categories !== 'object' || !this.parent.categories[lang] || Object.keys(this.parent.categories[lang]).length == 0) {
    //       this.parent.initCategories(lang);
    //       // console.log(':1: categories=', this.parent.categories);
    //       this.parent.appOptionSet('cats', JSON.stringify(this.parent.categories));
    //     }
    //     // eslint-disable-next-line vue/no-mutating-props
    //     this.parent.lang = lang;
    //     this.parent.userOption('set', 'lang', lang).then((res) => {
    //       // console.log(':2: userOption set lang, res=', lang, res);
    //     });

    //     if(!$this.parent.dictionary[lang]){
    //       this.loading = true;
    //       // console.log(':1:getDictionary $this.parent.dictionary=',$this.parent.dictionary);
    //       this.parent.getDictionary(lang).then(res=>{
    //         // console.log(':2:getDictionary res=', res);
    //         $this.parent.dictionary[lang] = res;
    //         $this.parent.messageObj = $this.parent.dictionary;
    //         $this.parent.message = $this.parent.dictionary;          
    //         $this.loading = false;
    //         if(lang !== old_lang){
    //           $this.parent.goToMy($this.parent.tab_name);
    //         }
    //       })      
    //     }
      
    //   }
    // },
    splitterModel: function () {
      // console.log(':: splitterModel=', this.splitterModel);
    },
  },
  computed: {
    badgeCompetece() {
      return this.listCompetence.length;
    },
    badgeAssessments() {
      let count = 0;
      for (let item of this.assessmentList) {
        if (!item.ro) {
          count++;
        }
      }
      return count;
    },
    flt_scheduled_tests() {
      if (!this.scheduled_tests) {
        return false;
      } else {
        let ret = this.scheduled_tests.filter((test, index) => {
          // console.log('::flt_scheduled_tests ',test.value, test.text,test.course_id, !test.course_id);
          return !test.course_id;
        });
        return ret;
      }
    },
    flt_user_tests() {
      if (!this.user_tests) {
        return false;
      } else {
        let ret = this.user_tests.filter((test, index) => {
          // console.log('::flt_user_tests ', test.test);
          return test.test && !test.test.course_id && !test.test.program_chedule_id;
        });
        return ret;
      }
    },

    flt_user_courses() {
      if (!this.user_courses) {
        return false;
      } else {
        let ret = this.user_courses.filter((course, index) => {
          // console.log('::flt_user_courses ', course);
          return course.course && !course.course.program_chedule_id;
        });
        return ret;
      }
    },
  },
  methods: {
    changePassword() {
      let $this = this;
      this.change_password_success = false;
      $this.change_password_fail = false;
      this.passw_loading = true;
      this.parent.POST(this.parent.apiurl + 'api/userpasswupdate', {id: this.parent.user_current.ID, password: this.password, member_id: this.parent.member_id}, function (res) {
        // console.log('::changePassword res=', res);
        $this.passw_loading = false;
        if (res.data) {
          $this.change_password_success = true;
        } else {
          $this.change_password_fail = true;
        }
      });
    },
    clearProfile() {
      let $this = this;
      $this.parent.do_confirm = () => {
        $this.parent.POST($this.parent.apiurl + 'api/profiledel', {user_id: $this.parent.user_current.ID, member_id: $this.parent.member_id}, function (res) {
          // console.log('::clearProfile res=', res);
          $this.parent.logout();
          $this.parent.confirm = false;
        });
      };
      $this.parent.title_confirm = this.messageObj[this.lang].clear_profile;
      $this.parent.message_confirm = this.messageObj[this.lang].clear_profile_msg;
      $this.parent.modal_ok = null;
      $this.parent.modal_cancel = null;

      $this.parent.confirm = true;
    },
    sendHit() {
      this.parent.appOptionGet('ddays').then((data) => {
        let ddays = data.DETAIL_TEXT == '[]' ? 0 : parseInt(data.DETAIL_TEXT);
        this.parent.sendHit(ddays, 'Y');
      });
    },
    self_edit(arg) {
      // console.log(':: self_edit arg=', arg);
      this.selfItem = arg.item;
      this.isShowDegree360ListLk = false;
      this.$nextTick(() => {
        this.isShowDegree360ListLk = true;
      });
    },
    self_close() {
      this.isShowDegree360List = false;
      this.isShowDegree360ListLk = false;
      this.$nextTick(() => {
        this.isShowDegree360List = true;
        this.isShowDegree360ListLk = true;
      });
    },
    self_task_update(degree360Task) {
      // let $this = this;
      // degree360Task.name = this.parent.replaceQuotes(degree360Task.name);
      // degree360Task.description = this.parent.replaceTags(degree360Task.description);
      // const method = "entityItemUpdate";
      // const params = {
      //   ENTITY: "uni_tdegree360",
      //   ID: degree360Task.id,
      //   NAME: degree360Task.name,
      // };
      // var url = $this.parent.bx24Init({ AJAX_ACTION: method, params: params });
      // $this.parent.bx24params.DETAIL_TEXT = JSON.stringify(degree360Task);
      // $this.parent.POST(url, $this.parent.bx24params, function (res) {
      //   console.log("::saveDegree360Task res=", res);
      //   $this.isShowDegree360ListLk = false;
      //   $this.$nextTick(() => {
      //     $this.isShowDegree360ListLk = true;
      //   });
      // });
    },
    addNewEvent() {
      let $this = this;
      $this.parent.eventItem = null;
      $this.parent.currentScreen = 'eventAdd';
    },
    editEvent(eventItem) {
      let $this = this;
      // console.log('::editEvent ', eventItem);
      $this.parent.eventItem = eventItem;
      $this.parent.currentScreen = 'eventAdd';
    },
    showInDates(item) {
      let today = dayjs().format('YYYY-MM-DD');
      return item.date_start <= today && (item.date_finish >= today || !item.date_finish);
    },
    isImApprInProfile(item) {
      let ret = -1;
      if (item.whois && item.whois.apprs) {
        let search = `|${this.parent.user_current.ID}|`;
        ret = item.whois.apprs.indexOf(search);
      }
      return ret !== -1;
    },
    initCanvas() {
      let width = 620;
      let height = 877;
      if (this.certificate.cert_orient) {
        height = 620;
        width = 877;
      }
      this.height = height;
      this.width = width;
      // console.log('::initCanvas w x h =', width, 'x', height, this.certificate);
      let $this = this;
      return new Promise((resolve) => {
        this.canvas = new fabric.StaticCanvas(this.$refs.canvas, {
          width: this.width,
          height: this.height,
          // backgroundColor: "#fff"
        });
        resolve(true);
      });
    },
    courseView(arg) {
      // console.log('::goCourse arg=', arg);
      this.toGoAgain(arg.course.ID, 'course');
    },
    split_debug(i = null) {
      const ret = this.debug.split('"');
      return i === null ? ret : ret[i];
    },
    lic_upd() {
      this.lic_to = dayjs().add(this.lic_model.month, 'month').format('YYYY-MM-DD');
    },
    disk_upd() {
      this.disk_to = dayjs().add(this.disk_model.month, 'month').format('YYYY-MM-DD');
    },
    theme_upd() {
      this.theme_to = dayjs().add(this.theme_model.month, 'month').format('YYYY-MM-DD');
    },
    subac_upd() {
      this.subac_to = dayjs().add(this.subac_model.month, 'month').format('YYYY-MM-DD');
    },
    proc_debug() {
      let [uid, moder, admin, pr] = this.split_debug();
      const ph = this.parent.c53(pr);
      // console.log(ph);
      if (ph == 6508133905614859) {
        if (this.debug2) {
          this.debug3 = {};
          if (this.lic_model && this.lic_model.month) {
            this.debug3.lic = {
              name: this.lic_model.name,
              count: this.lic_model.count,
              to: this.lic_to,
            };
          }
          if (this.disk_model && this.disk_model.month) {
            this.debug3.disk = {
              name: this.disk_model.name,
              mb: this.disk_model.mb,
              to: this.disk_to,
            };
          }
          if (this.theme_model && this.theme_model.month) {
            this.debug3.theme = {
              name: this.theme_model.name,
              to: this.theme_to,
            };
          }
          if (this.subac_model && this.subac_model.month) {
            this.debug3.subac = {
              name: this.subac_model.name,
              count: this.subac_model.count,
              to: this.subac_to,
            };
          }

          this.debug3_h = this.parent.c53(JSON.stringify(this.debug3));
          const h2 = this.parent.c53(this.debug2);
          this.debug2_h = this.parent.c53(`${h2}${this.debug3_h}`);

          const key = {
            key: this.debug2_h,
            tariff: this.debug3,
            hash: this.debug3_h,
          };
          this.key_str = JSON.stringify(key);
          if (!this.parent.isJSON(this.key_str)) {
            this.key_str = 'Ошибка json';
          } else {
            // Создаем Blob из строки JSON
            const blob = new Blob([this.key_str], {type: 'application/json'});
            // Сохраняем Blob как файл
            saveAs(blob, this.debug2 + '.key');

            // saveAs(new Blob([this.parent.s2ab(this.key_str)],
            // {type: "application/json"}), this.debug2 + '.key');
          }
        } else {
          uid = uid / 10000;
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.user_current = {
            ID: uid,
            LAST_NAME: 'debug',
            NAME: 'Brusnika.LMS',
            UF_DEPARTMENT: this.parent.user_current.UF_DEPARTMENT,
          };
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isModer = moder === '*';
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.isAdmin = admin === '*';
          if (admin && admin !== '*') {
            let $this = this;
            $this.parent.member_id_debug = admin;
            $this.parent.portal_debug = moder;
            $this.parent.isModer = true;
            $this.parent.initApp();
          }
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.debug_on = true;

          // console.log(this.parent.user_current);
          // console.log(this.parent.isModer);
          // console.log(this.parent.isAdmin);
          this.$emit('goback', '');
        }
      } else {
        // console.log('not fouond');
      }
    },

    mess_settings_save() {
      this.$emit('mess_settings_save', this.mess_settings_model);
      this.settings_dialog = false;
    },
    delCert(index, item) {
      // console.log('::delCert ', index, item);
      if (item.H) {
        this.clearCertImage(index, item.N);
      } else {
        this.delCertFile(index);
      }
    },
    clearCertImage(index, name) {
      let $this = this;
      // console.log('clearVid ', index);
      let url = `${this.parent.app_server_url}api/clearimage?client_key=${this.parent.member_id}&portal=${this.parent.portal}&name=${name}`;
      $this.parent.POST(url, {name: name}, function (response) {
        // console.log('::clearVid response=', response);
        $this.ext_certs.splice(index, 1);
        $this.saveProfile();
      });
    },
    delCertFile(index) {
      let $this = this;
      let params = {
        ENTITY: 'uni_files',
        ID: this.ext_certs[index].I,
      };
      let url = $this.parent.bx24Init({AJAX_ACTION: 'entityItemDel', params: params}); //.then(params=> {
      $this.parent.b24POST(
        url,
        $this.parent.bx24params,
        function (res) {
          $this.ext_certs.splice(index, 1);
          $this.saveProfile();
        },
        'json'
      );
    },
    loadCerts() {
      const $this = this;
      let params = {
        ENTITY: 'uni_files',
        SORT: {},
        FILTER: {NAME: 'extcert_' + $this.parent.user_current.ID},
      };

      this.ext_certs = this.ext_certs ? this.ext_certs : [];
      return new Promise(function (resolve) {
        let url = $this.parent.bx24Init({AJAX_ACTION: 'entityItemGet', params: params}); //.then(params=> {
        $this.parent.b24POST(
          url,
          $this.parent.bx24params,
          function (res) {
            // console.log(':***:loadCerts Finished!', res);
            if (res.result) {
              let files = res.result;
              for (let item of files) {
                //console.log('::loadFiles file=',i,Object.keys(files).length, file);
                $this.ext_certs.push({
                  I: item.ID,
                  N: item.PREVIEW_TEXT,
                  data: item.DETAIL_TEXT,
                });
              }
              resolve('::loadCerts OK');
            } else {
              resolve(':: loadCerts error');
            }
          },
          'json'
        );
      });
    },
    saveCertFiles(isStart) {
      // console.log('::saveCertFiles listFiles=', [...this.listFiles]);
      var $this = this;
      if (isStart) {
        // Первый запуск
        this.ext_certs = this.ext_certs ? this.ext_certs : [];
        this.isCertsLoading = true;
      }
      if (Object.keys(this.listFiles).length > 0) {
        let itemFile = this.listFiles.pop(); //достали последний файл
        // console.log(':*1*:saveCertFiles itemFile=', itemFile);
        if (!itemFile.I) {
          $this.parent.readFileFromDisk(itemFile).then((item) => {
            // console.log(':*2*:readFileFromDisk item=', item);
            // var method = 'entityItemAdd';
            // let params = {
            //   'ENTITY': 'uni_files',
            //   'PREVIEW_TEXT': item.name,
            //   'NAME': 'extcert_' + this.parent.user_current.ID,
            // };
            // var url = $this.parent.bx24Init({ AJAX_ACTION: method, params:params });
            // $this.parent.bx24params.DETAIL_TEXT = item.data;
            // // console.log('::saveCertFiles params=', params, url);
            // $this.parent.POST(url, $this.parent.bx24params, function (res) {
            //   // console.log('::saveCertFiles Finished!', res);
            //   if (res.result && !isNaN(parseInt(res.result))) {
            //     var file_id = parseInt(res.result);
            //     $this.ext_certs.push({
            //       I: file_id,
            //       N: item.name,
            //       data: item.data
            //     });
            //   }
            //
            //   $this.saveCertFiles();
            // });
            $this.parent.sendImage(item.data, item.name).then((res) => {
              // console.log(':*3*:sendImage res=', res);
              if (res) {
                if (
                  !$this.ext_certs.find((el) => {
                    return el.N == res.name;
                  })
                ) {
                  $this.ext_certs.push({
                    I: 'no_id' + $this.parent.getRandomNumber(),
                    N: res.name,
                    H: res.href,
                  });
                }
              }
              $this.saveCertFiles();
            });
          });
        } else {
          $this.ext_certs.push({
            I: itemFile.I,
            H: itemFile.H,
            N: itemFile.name,
          });
          $this.saveCertFiles();
        }
      } else {
        $this.isCertsLoading = false;
        $this.saveProfile();
      }
    },
    // sendImage(base64, name){
    //   let $this=this;
    //   console.log('::doSend name=', name);
    //   let url=`${this.parent.app_server_url}api/sendmedia?client_key=${this.parent.member_id}&portal=${this.parent.portal}&name=${name}`;
    //   return new Promise((resolve, reject) => {
    //     $this.parent.POST(url, {base64:base64, namr:name}, function (response) {
    //       // console.log('::callMethod response=', response);
    //       if (response) {
    //         resolve(response);
    //       } else {
    //         reject(false);
    //       }
    //     });
    //   });

    // },
    fileListChange(listFiles) {
      this.listFiles = listFiles;
      // console.log('::fileListChange listFiles=', listFiles);
    },
    onRejectedAny(rejectedEntries) {
      // console.log('::onRejectedAny ', rejectedEntries);
      let $this = this;
      if (rejectedEntries[0]) {
        let item = rejectedEntries[0];
        // eslint-disable-next-line vue/no-mutating-props
        this.parent.notify_text = '';
        $this.parent.notify_title = this.message[this.lang].attention;
        if (item.failedPropValidation == 'max-file-size') {
          $this.parent.notify_text = this.message[this.lang].max_file_size;
        } else if (item.failedPropValidation == 'accept') {
          $this.parent.notify_text = this.message[this.lang].accept;
        }
        if (this.parent.notify_text) {
          $this.parent.notify = true;
        }
      }
    },
    saveCompetence(index, publish, type) {
      let $this = this;
      type = type ? type : 'competence';
      let competence = this.listCompetence[index];
      let name = competence.name;
      if (type === 'profile') {
        // юзер может быть как согласующий(apprs) и как сотрудник(users)
        let json_name = {apprs: [competence.agr.id], users: []};
        if (competence.inlk === 'Y') {
          for (let item of competence.users) {
            json_name.users.push(item.id);
          }
        }
        if (competence.on_appr === 'Y') {
          for (let item of competence.appr) {
            json_name.apprs.push(item.id);
          }
        }
        name = JSON.stringify(json_name);
      }
      //
      // this.saving = true;
      // console.log('::saveCompetence name=', name, competence);
      this.parent.competenceSave(type, name, competence, competence.id, publish).then((res) => {
        $this.parent.competenceGetList('profile', false, $this.parent.user_current.ID).then((res) => {
          // console.log('::main profiles_on_appr=', res);
          $this.parent.my_competence = res;
        });
      });
    },
    chekForPublish(index) {
      let published = this.isAllApprooved(index) && this.listCompetence[index].agr.checked ? 'Y' : 'N';
      this.listCompetence[index].published = published;
      this.saveCompetence(index, published, 'profile');
      // console.log('::chekForPublish ', this.listCompetence[index]);
    },
    // все ли согласовали
    isAllApprooved(index) {
      let ret = true;
      for (let item of this.listCompetence[index].appr) {
        if (!item.checked) {
          ret = false;
          break;
        }
      }
      return ret;
    },
    showAssName(name) {
      let ret = name;
      let arName = name.split('###');
      if (arName.length === 2) {
        ret = arName[1];
      }
      return ret;
    },
    loadOpensLIst() {
      let $this = this;
      this.loading = true;
      this.parent.opensGetForUser(this.parent.user_current.ID, this.isShowChecked).then((listOpens) => {
        // console.log(':: opensGetForUser listOpens=', listOpens);
        if (listOpens) {
          // eslint-disable-next-line vue/no-mutating-props
          this.parent.listOpens = listOpens;
          this.item_DETAIL_TEXT_s = [];
          this.status_model = [];
          for (let i in this.parent.listOpens) {
            let item = this.parent.listOpens[i];
            if (this.parent.isJSON(item.DETAIL_TEXT)) {
              // console.log(':: ',item.DETAIL_TEXT);
              // eslint-disable-next-line vue/no-mutating-props
              this.parent.listOpens[i].DETAIL_TEXT = JSON.parse(item.DETAIL_TEXT);
              let value = this.parent.listOpens[i].DETAIL_TEXT.s;
              let text = this.parent.getTextByValue(value, this.status_options[this.lang]);
              // console.log(':: value=',value, text);
              this.item_DETAIL_TEXT_s.push(value);
              this.status_model.push(text);
              if ($this.parent.isJSON($this.parent.listOpens[i].DETAIL_TEXT.f)) {
                $this.parent.listOpens[i].DETAIL_TEXT.f = JSON.parse($this.parent.listOpens[i].DETAIL_TEXT.f);
              }
            }
          }
        }
        this.loading = false;
      });
    },
    toggleChecked(mode) {
      this.isShowChecked = mode;
      this.loadOpensLIst();
      // this.$nextTick(()=>{
      //   this.isShowChecked = mode;
      // })
    },
    showAttempt(list, index) {
      let ret = 1;
      if (list && Object.keys(list).length > 1) {
        ret = Object.keys(list).length - index;
      }
      return ret;
    },
    change_status_model(index) {
      let s = this.parent.getValueByText(this.status_model[index], this.status_options[this.lang]);
      // console.log(':;change_status_model ',s)
      this.item_DETAIL_TEXT_s[index] = s;
    },
    showStarttime(unixTimeStamp) {
      let formatDate = this.parent.appRegion == 'ru' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';
      return unixTimeStamp ? dayjs(unixTimeStamp * 1000).format(formatDate) : '';
    },
    showTime(timestamp_x) {
      let formatDate = this.parent.appRegion == 'ru' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm';
      if (parseInt(timestamp_x) < 9999999999) {
        timestamp_x *= 1000;
      }
      return timestamp_x ? dayjs(timestamp_x).format(formatDate) : '';
    },
    showResults(item) {
      // console.log('::showResults item=', item);
      let status = '';
      if (item.test_passed) {
        status = this.message[this.lang].passed;
      } else {
        status = this.message[this.lang].nopass;
      }
      //
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_full_width = true;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_title = item.test_name + ' - ' + status + ' ' + item.test_result + '%';
      //
      let html = '<table width="100%"><tr>' + '<th style="min-width: 40px">?</th><th>' + this.message[this.lang]['col_2'] + '</th>' + '<th style="min-width: 150px">' + this.message[this.lang]['col_3'] + '</th>' + '</tr>';
      let nn = 0;
      if(item?.wrongAnswers?.length > 0){
        for (let row of item.wrongAnswers) {
          if (row.qt) {
            html += '<tr style="background-color: #eeeeee; border-bottom: white 2px solid;"><td> </td><td align="left" style="padding-bottom: 10px; padding-top: 10px"><b>' + this.parent.showBase64Text(row.qt) + '</b></td>';
          }
          let qa = '';
          let qPass = true;
          if (row.type == 'qa') {
            let nn = 0,
              pp = 0;
            for (let a of row.a) {
              if (a.checked && a.right) {
                pp++;
                qa += '<tr><td>' + pp + '.</td><td align="left">' + a.text + '</td><td style="color:green">' + this.message[this.lang]['selected_right'] + '</td></tr>';
              } else if (a.checked && !a.right) {
                pp++;
                qa += '<tr><td>' + pp + '.</td><td align="left">' + a.text + '</td><td style="color:red">' + this.message[this.lang]['selected_noright'] + '</td></tr>';
                qPass = false;
              } else if (!a.checked && a.right) {
                pp++;
                qa += '<tr><td>' + pp + '.</td><td align="left">' + a.text + '</td><td style="color:red">' + this.message[this.lang]['noselected_noright'] + '</td></tr>';
                qPass = false;
              } else if (!a.checked && !a.right) {
                pp++;
                qa += '<tr><td>' + pp + '.</td><td align="left">' + a.text + '</td><td style="color:green">' + this.message[this.lang]['noselected_right'] + '</td></tr>';
              } else {
                nn++;
              }
            }
            if (nn == Object.keys(row.a).length) {
              qPass = false;
              qa += '<tr><td> </td><td align="center" style="color:red">' + this.message[this.lang]['no_answers'] + '</td><td style="color:red">' + this.message[this.lang]['noright'] + '</td></tr>';
            }
          } else if (row.type == 'seq' || row.type == 'eqw') {
            //ответ на  вопрос-последовательность
            let a = row.a;
            let et = row.et;
            qPass = row.right;
            let tab = '<table>';
            for (let i = 0; i < a.length; i++) {
              let style = a[i].value == et[i].value ? 'color:green' : 'color:red';
              let et_text = row.type == 'seq' ? et[i].text : et[i].text2;
              tab += '<tr style="border-bottom: #cccccc 1px solid; padding-bottom: 5px; padding-top: 5px;"><td>' + et_text + '</td>' + '<td> - </td><td style="' + style + '">' + a[i].text + '</td></tr>';
            }
            tab += '</table>';
            qa += '<tr><td> </td><td align="center" colspan="2">' + tab + '</td></tr>';
          } else if (row.a && row.a[0] && row.a[0].opn == 'Y') {
            //ответ на открытый вопрос
            // console.log(':: row=',row)
            if (row.a[0]) {
              let a = row.a[0];
              nn++;

              let com = a.com ? ' [' + a.com + ']' : '';
              qa += '<tr style="background-color: #eeeeee; border-bottom: white 2px solid;"><td align="center">' + nn + '</td>';
              if (a.checked && a.right) {
                qa += '<td align="left">' + this.parent.showBase64Text(a.text) + com + '</td><td align="center" style="color:green">' + this.message[this.lang]['right'] + '</td></tr>';
                qPass = true;
              } else if (a.checked && !a.right) {
                qPass = false;
                qa += '<td align="left">' + this.parent.showBase64Text(a.text) + com + '</td><td align="center" style="color:red">' + this.message[this.lang]['noright'] + '</td></tr>';
              } else {
                qPass = false;
                qa += '<td align="left" style="color:red">' + this.message[this.lang]['open_question'] + '</td><td align="center" style="color:red">' + this.message[this.lang]['nochecked'] + '</td></tr>';
              }
            }
          } else if (row.type == 'sub') {
            let nn = 0,
              pp = 0;
            qa += '<tr><td> </td><td align="left">';
            let subtable = '<table>';
            subtable += '<tr><th>' + this.message[this.lang].subtable_col_1 + '</th><th>' + this.message[this.lang].subtable_col_2 + '</th><th>' + this.message[this.lang].subtable_col_3 + '</th>';

            for (let a of row.a) {
              if (a.right) {
                subtable += '<tr><td>' + a.text + '</td><td>' + row.et[pp] + '</td><td style="color:green">' + this.message[this.lang]['right'] + '</td></tr>';
              } else {
                subtable += '<tr><td>' + a.text + '</td><td>' + row.et[pp] + '</td><td style="color:red">' + this.message[this.lang]['noright'] + '</td></tr>';
                qPass = false;
              }
              pp++;
            }
            subtable += '</table>';
            qa += subtable;
            qa += '</td><td></td></tr>';
          }

          if (row.a && row.a[0] && row.a[0].opn == 'Y') {
            html += '<td> </td></tr>';
          } else if (row.qt) {
            if (qPass) html += '<td style="color:green">' + this.message[this.lang]['right'] + '</td></tr>';
            else html += '<td style="color:red">' + this.message[this.lang]['noright'] + '</td></tr>';
          }
          if (parseInt(item.show_results) > 1 || (row.type == 'opn' && parseInt(item.show_results) > 0)) {
            html += qa;
          }
          // html+='<tr style="border-bottom: #cccccc dotted 1px; padding-bottom: 10px;"><td> </td><td></td></tr>';
        }
      }
      html += '</table>';
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_size = 'xl';
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_list = '';
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_message = html;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_filterFn = null;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.okModal = '';
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.modal_cancel = this.message[this.lang].close;
      // eslint-disable-next-line vue/no-mutating-props
      this.parent.showModal();
    },

    dangerClassCoursesSchedules() {
      if (Object.keys(this.scheduled_courses).length > 0) {
        return ['', 'text-danger'];
      } else {
        return [];
      }
    },
    badgeCoursesSchedules() {
      if (this.scheduled_courses && Object.keys(this.scheduled_courses).length > 0) {
        return Object.keys(this.scheduled_courses).length;
      } else {
        return false;
      }
    },
    dangerClassTestsSchedules() {
      if (Object.keys(this.scheduled_tests).length > 0) {
        return ['', 'text-danger'];
      } else {
        return [];
      }
    },
    badgeTestsSchedules() {
      if (this.scheduled_tests && Object.keys(this.scheduled_tests).length > 0) {
        return Object.keys(this.scheduled_tests).length;
      } else {
        return false;
      }
    },
    checkCoursesAndTests() {
      // console.log(':---0:checkCoursesAndTests user_current ID=', this.parent.user_current.ID);

      this.parent.getUserTests().then(
        (ok) => {
          // console.log(':1:checkCoursesAndTests ',ok);
          let passedTests = ok.passedTests;
          // this.user_tests = ok.user_tests;
          // this.parent.checkIsCourseEnded(passedTests).then(ok=>{
          this.getUserCourses(passedTests);
          // }, err=>{console.log(err)});
        },
        (err) => {
          // this.parent.checkIsCourseEnded().then(ok=> {
          this.getUserCourses();
          // }, err=>{console.log(err)});
        }
      );
    },
    showScore(value) {
      return this.status_options[this.lang].find((element, index, array) => {
        if (element.value === value) return true;
        else return false;
      }, this)['text'];
    },
    saveRespScore(item, index) {
      let $this = this;
      console.log('::saveRespScore item=', item);
      // $this.parent.notify_text = '';
      // $this.parent.notify_title = this.message[this.lang].score_saved;
      // $this.parent.notify = true;

      let score = this.item_DETAIL_TEXT_s[index]; // Y|N - оценка
      item.DETAIL_TEXT.s = score;
      let program_chedule_id = item.DETAIL_TEXT.p;

      const [resp_id, uniq_test_id, quest_id, student_id] = item.NAME.split('-');

      let [test_id, startTime] = uniq_test_id.split('_');
      test_id = parseInt(test_id);

      this.parent.openSet(resp_id, student_id, uniq_test_id, quest_id, item.DETAIL_TEXT, item.ID);

      if (!student_id) {
        $this.parent.notify_text = '';
        $this.parent.notify_title = this.message[this.lang].score_bad_format;
        $this.parent.notify_warning = true;
        $this.parent.notify = true;
        return;
      }

      let corr_passed = score === 'Y' ? 1 : score === 'N' ? -1 : 0;
      $this.parent.listOpens[index].DETAIL_TEXT.s = score;
      let waItem = {
        checked: true,
        right: score === 'Y',
        text: item.DETAIL_TEXT.a,
        com: item.DETAIL_TEXT.c,
        opn: 'Y',
      };

      // console.log('--- Запишем оценку в тест студента и обновим его статус ---');
      // console.log(':: ',student_id, test_id, startTime);

      // console.log('--- Запишем оценку в тест студента и обновим его статус --- waItem=', waItem);
      // console.log(`::отв=${resp_id} студ=${student_id} тест=${test_id} startTime=${startTime}`);
      this.parent.uCoursesTestsGetActive('test', student_id, test_id, true, startTime).then(
        (res) => {
          console.log('--- тест найден ---');
          let test = res.data;
          let uTest_id = res.id;
          let test_score = parseInt(test.test_score);
          let wa = test.wrongAnswers;
          if (!wa) {
            wa = [{q: '', a: []}];
          }
          //
          let indQuest = wa.findIndex((element, index, array) => {
            if (element.q == quest_id) return true;
            else return false;
          }, this);
          if (indQuest >= 0) {
            wa[indQuest].a = [waItem];
          } else {
            wa.push({q: quest_id, a: [waItem]});
          }
          // console.log(':: wa=', wa);
          let count_passed = 0;
          for (let i in wa) {
            let q = wa[i];
            if (q.type === 'opn') {
              // console.log(':: q=', q);

              if (q.a && q.a[0] && q.a[0].right) {
                count_passed++;
                // console.log(':: count_passed++ =', count_passed);
              }
            } else {
              if (q.right) {
                count_passed++;
              }
            }
            // console.log(':'+count_passed+': q=',q)
          }
          let percentsPassed = (100 * count_passed) / Object.keys(wa).length;
          // console.log(':: percentsPassed=', percentsPassed);
          let test_passed = test_score <= percentsPassed;
          test.test_result = percentsPassed.toFixed(0) + '/' + test_score; //count_passed + '/' + Object.keys(wa).length;
          test.test_passed = test_passed;
          test.status = test_passed ? 'Y' : 'N';
          test.wrongAnswers = wa;

          /**
           * если test_passed и есть program_chedule_id то это или тест или тест в курсе из программы
           * Найдем юзер-назначение и если есть отдельный тест в назначении, поставим ему статус
           */
          if (test_passed && program_chedule_id) {
            $this.setStatusProgramScheduleModule(program_chedule_id, test_id);
          }

          // $this.parent.uCoursesTestsSave('test', student_id, test, 'N', true).then( res=>{
          $this.parent.uCoursesTestsSet('test', student_id, test, uTest_id).then((res) => {
            // console.log(':***:uCoursesTestsSave saved userTest student_id, uTest_id,res=', student_id, uTest_id, test, res);
            if (!$this.parent.notify) {
              $this.parent.notify_text = '';
              $this.parent.notify_title = this.message[this.lang].score_saved;
              $this.parent.notify_warning = false;
              $this.parent.notify = true;

              //Уведомление студенту если он не отключил его
              //item.DETAIL_TEXT.q - вопрос
              //item.Dn_tasksETAIL_TEXT.a - ответ
              //item.DETAIL_TEXT.c - комментарий проверяющего
              this.parent.isNoteSet([student_id], 'open').then((checks) => {
                if (Object.keys(checks).length > 0) {
                  let message = this.message[this.lang].u_opn_checked + '. <br>';
                  message += item.DETAIL_TEXT.s === 'Y' ? this.message[this.lang].u_opn_y : this.message[this.lang].u_opn_n + '<br>';
                  message += this.message[this.lang].u_opn_q + ': ' + item.DETAIL_TEXT.q + '<br>';
                  message += this.message[this.lang].u_opn_a + ': ' + item.DETAIL_TEXT.a + '<br>';
                  message += this.message[this.lang].u_opn_c + ': ' + item.DETAIL_TEXT.c;
                  $this.parent.notifyLK(student_id, message);
                  // let arBatch2 = {};
                  // arBatch2["note_" + student_id] = [
                  //   "im.notify.system.add",
                  //   {
                  //     USER_ID: student_id,
                  //     MESSAGE: message,
                  //     MESSAGE_OUT: message,
                  //   },
                  // ];
                  // $this.parent.callBatch(arBatch2, (res) => {
                  //   console.log("::Уведомления отправлены res=", message);
                  // });
                }
              });
            }
            $this.saveRespScoreInCourse(test_id, startTime, quest_id, student_id, waItem, program_chedule_id);
          });
        },
        (err) => {
          // console.error('Не найдена попытка в тестах для ', student_id, test_id, startTime, dayjs(startTime * 1000).format('YYYY-MM-DD HH:mm:s'));
          //И надо еще посмотреть в тестах курсов и если там есть этотже тест, то внести информацию в него тоже
          //Причем в каждый курс где есть такой тест
          $this.saveRespScoreInCourse(test_id, startTime, quest_id, student_id, waItem, program_chedule_id);
          // $this.parent.notify_title = this.message[this.lang].at_ten_tion;
          // $this.parent.notify_text = this.message[this.lang].attempt_not_found;
          // $this.parent.notify_warning = true;
          // $this.parent.notify = true;
        }
      );
    },
    saveRespScoreInCourse(test_id, startTime, quest_id, student_id, waItem, program_chedule_id) {
      let u1 = dayjs(startTime * 1000)
        .subtract(60, 'day')
        .format('YYYY-MM-DD');
      let u2 = dayjs().format('YYYY-MM-DD');
      // console.log(':*0*:saveRespScoreInCourse courses=> ',test_id, startTime, quest_id, student_id, u1, u2);
      let $this = this;
      this.parent.uCoursesTestsGet('course', student_id, u1, u2, 'withId', true).then(
        (userCourses) => {
          // console.log(':*1*:uCourses TestsGet courses=> ',userCourses);
          if (userCourses) {
            for (let courseItem of userCourses) {
              let course = courseItem.data; // course.course_id - id самого курса
              let course_id = courseItem.id; // 'это юзер-курс id'
              // console.log(course.tests);
              if (!course || !course.tests || course.tests.length == 0) {
                // console.log("continue...");
                continue;
              }
              let i = course.tests.findIndex((el) => {
                return el.test_id == test_id && el.startTime == startTime;
              });
              // console.log(':: i=',i);
              if (i != -1) {
                // console.log(':!!!: founded course=',course);
                let test_score = parseInt(course.tests[i].test_score);

                let wa = course.tests[i].wrongAnswers;
                if (!wa) {
                  wa = [{q: '', a: []}];
                }
                //
                let indQuest = wa.findIndex((element, index, array) => {
                  if (element.q == quest_id) return true;
                  else return false;
                }, this);
                if (indQuest >= 0) {
                  // console.log(':: найден вопрос quest_id=', quest_id);
                  wa[indQuest].a = [waItem];
                } else {
                  // console.log('::НЕ найден вопрос quest_id=', quest_id);
                  wa.push({q: quest_id, a: [waItem]});
                }
                let count_passed = 0;
                for (let i in wa) {
                  let q = wa[i];
                  if (q.type === 'opn') {
                    if (q.a && q.a[0] && q.a[0].right) {
                      count_passed++;
                    }
                  } else {
                    if (q.right) {
                      count_passed++;
                    }
                  }
                }
                let percentsPassed = (100 * count_passed) / Object.keys(wa).length;
                let test_passed = test_score <= percentsPassed;
                course.tests[i].test_result = percentsPassed.toFixed(0) + '/' + test_score; //count_passed + '/' + Object.keys(wa).length;
                course.tests[i].test_passed = test_passed;
                course.tests[i].status = test_passed ? 'Y' : 'N';
                course.tests[i].wrongAnswers = wa;
                /**
                 * В тест курса записана проверка открытого вопроса и скорректирован результат теста
                 * Запишем его в юзер-курс и сохраним в попытки
                 */

                $this.parent.uCoursesTestsSet('course', student_id, course, course_id).then((res) => {
                  // console.log(':*1*:uCoursesTestsSave saved course_id,res, userCourse=',course_id,res, course);
                  if (!$this.parent.notify) {
                    $this.parent.notify_text = '';
                    $this.parent.notify_title = $this.message[$this.lang].score_saved;
                    $this.parent.notify = true;
                  }
                  /**
                   * Посмотрим на попытки в юзер-курсах и определим статус курса
                   */
                  //  console.log(':!!!: course=',course);
                  $this.parent.getSummaryUserCourses(student_id, course.course_id).then(
                    (res) => {
                      // console.log(':*2*:getSummaryUserCourses res=',res);
                      if (res) {
                        let passed = res.passed;
                        if (passed) {
                          //Курс пройден, запишем в юзер-курс
                          course.passed = true;
                          $this.parent.uCoursesTestsSet('course', student_id, course, course_id).then((res) => {
                            // console.log(':*3*:uCoursesTestsSave passed course_id,res,userCourse=',course_id,res, course);
                          });
                          /**
                           * Если задан program_chedule_id, то ищем в юзер-назначении модуль с course_id
                           * и ставим ему статус пройден
                           */
                          if (program_chedule_id) {
                            $this.setStatusProgramScheduleModule(program_chedule_id, course.course_id);
                          }
                        }
                      }
                    },
                    (err) => {
                      console.error(':*2*:getSummaryUserCourses err=', err);
                    }
                  );
                });
                //
              } else {
                // console.error('Не найдена попытка в курсах для ',student_id, test_id, startTime, dayjs(startTime*1000).format('YYYY-MM-DD HH:mm:s'))
                if (!$this.parent.notify) {
                  $this.parent.notify_title = this.message[this.lang].at_ten_tion;
                  $this.parent.notify_text = this.message[this.lang].attempt_not_found;
                  $this.parent.notify_warning = true;
                  $this.parent.notify = true;
                }
              }
            } //for
          }
        },
        (err) => {}
      );
    },
    setStatusProgramScheduleModule(program_chedule_id, module_id) {
      console.log(':+++:setStatusProgramScheduleModule ', program_chedule_id, module_id);
      let $this = this;
      let params = {
        ENTITY: 'uni_uprogscheds',
        FILTER: {
          ID: parseInt(program_chedule_id),
        },
      };
      // console.log(':1: params=', params)

      let url = $this.parent.bx24Init({AJAX_ACTION: 'entityItemGet', params: params});
      $this.parent.b24POST(
        url,
        $this.parent.bx24params,
        function (res) {
          if (res && res.result && res.result.length) {
            let modules = $this.parent.isJSON(res.result[0].DETAIL_TEXT) ? JSON.parse(res.result[0].DETAIL_TEXT) : [];
            console.log(':2: entityItemGet modules=', modules)
            // let item = modules.find((el) => {
            //   return el.mid == module_id;
            // });
            let count = 0;
            for (let i = 0; i < modules.length; i++) {
              for (let j = 0; j < modules[i].length; j++) {
                if (modules[i][j].mid == module_id) {
                  modules[i][j].passed = 'Y';
                  count++;
                }
              }
            }
            console.log(':2.1: count=', count);
            if (count>0) {
              // item.passed = 'Y';
              let params = {
                ENTITY: 'uni_uprogscheds',
                ID: parseInt(program_chedule_id),
                DETAIL_TEXT: JSON.stringify(modules),
              };
              
              console.log(':3: params=', params)

              let url = $this.parent.bx24Init({
                AJAX_ACTION: 'entityItemUpdate',
                params: params,
              });
              $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
                console.log(':4: entityItemUpdate res=', res)
              });
            }
          } else {
            console.error(':5: entityItemGet res=', res);
          }
        },
        'json'
      );
    },
    dangerClass() {
      if (this.parent.checkOpensAlert()) {
        return ['', 'text-danger'];
      } else {
        return [];
      }
    },
    toggleShowCollapse(index) {
      if (this.showCollapse[index]) {
        this.showCollapse = [];
      } else {
        this.showCollapse = [];
        this.showCollapse[index] = true;
      }
    },
    closeAss() {
      this.assesment = '';
    },
    showMyScores(item) {
      // console.log('::showMyScores item=',item)
      let html = '';
      let user_id = '' + this.parent.user_current.ID;
      let competence = [];
      if (item.scores && Object.keys(item.scores).length > 0 && item.scores[0].hasOwnProperty('ind_c')) {
        for (let ind_c in item.competences) {
          let name = item.competences[ind_c].name;
          let indicators = [];
          for (let ind_i in item.competences[ind_c].cats) {
            let indicator = item.competences[ind_c].cats[ind_i];
            // console.log(':: indicator= ', indicator);
            let avg = 0;
            let ind_len = 0;
            let my_score = 0;
            let scoreItem = item.scores.find((el) => {
              return el.ind_c == ind_c && el.ind_i == ind_i;
            });
            if (scoreItem) {
              for (let uid in scoreItem.selected) {
                avg += scoreItem.selected[uid] * 10;
                if (uid == this.parent.user_current.ID) {
                  my_score = scoreItem.selected[uid] * 10;
                }
                ind_len++;
              }
              if (ind_len > 0) {
                avg = (avg / ind_len).toFixed(1);
              }
              indicators.push({
                ind_name: indicator.text,
                avg: avg,
                my_score: my_score,
              });
            } //if scoreItem
          } //ind_i
          competence.push({
            name: name,
            indicators: indicators,
          });
        } //ind_c
      }
      //Устаревшее убрать в 2024
      else {
        for (let i in item.scores) {
          // console.log(':1: ',item.scores[i]);
          let name = item.scores[i].name;
          let indicators = [];
          for (let j in item.scores[i].indicators) {
            let indicator = item.scores[i].indicators[j];
            // console.log(':2: indicator=', indicator);
            let avg = 0;
            let ind_len = 0;
            let my_score = 0;
            for (let uid in indicator.selected) {
              avg += indicator.selected[uid].value.index * 10;
              if (uid == this.parent.user_current.ID) {
                my_score = indicator.selected[uid].value.index * 10;
              }
              ind_len++;
            }
            if (ind_len > 0) {
              avg = (avg / ind_len).toFixed(1);
            }
            indicators.push({
              ind_name: indicator.competence,
              avg: avg,
              my_score: my_score,
            });
          }
          competence.push({
            name: name,
            indicators: indicators,
          });
        }
      } //2024

      // console.log('::showMyScores competence=',competence);
      let header = item.isImPersona ? this.message[this.lang].scores_avg : this.message[this.lang].scores_yors;
      html = `<div class="text-center" style="border-bottom: #eee solid 0.5px">${header}</div>`;
      for (let row in competence) {
        html += '<div style="background-color: #eeeeee; padding 5px 5px 5px 5px">' + competence[row].name + '</div>';
        for (let subrow in competence[row].indicators) {
          html += '<div style="border-bottom: #eee solid 0.5px" class="row"><div class="col-1"></div><div class="col-5 text-left"> ' + competence[row].indicators[subrow].ind_name + ' </div><div class="col-2 text-right"> ';

          if (item.isImPersona) {
            //оценка мне, усредненная
            html += competence[row].indicators[subrow].avg;
          } else if (item.isImExpert) {
            html += competence[row].indicators[subrow].my_score;
          }
          html += ' </div></div>';
        }
      }

      // this.initChart(item);
      return html;
    },
    showAssessment(iutem, index) {
      this.showChart = false;
      if (this.showAss[index]) {
        this.showAss = [];
      } else {
        this.showAss = [];
        this.showAss[index] = true;
      }
      this.btnChart = false;
    },
    scores_saved(assessment) {
      // console.log(':: scores_saved',assessment);
      this.parent.assessmentUpdate(assessment).then(
        (res) => {
          //Баллы за оценивание текущим юзером как оценивающим
          this.parent.savePoints('S', assessment.id);
          // console.log(res);
          this.assessmentList = [];
          this.loadMyAssessments('experts').then((res) => {
            this.loadMyAssessments('persona');
          });
          // this.parent.loadMyAssessments();
        },
        (err) => {
          console.error(err);
          this.assessmentList = [];
          this.loadMyAssessments('experts').then((res) => {
            this.loadMyAssessments('persona');
          });
        }
      );
    },
    scores_return(arg) {
      // console.log(':: scores_return',arg);
      this.assesment = '';
    },

    viewAssessment(item, index) {
      this.assesment = item;
    },
    customFormatter(date) {
      let format = this.parent.appRegion == 'ru' ? 'DD.MM.YYYY' : 'YYYY-MM-DD';
      let ret = '';
      if (!date) {
        ret = ''; //dayjs().format('DD.MM.YYYY');
      } else {
        ret = dayjs(date).format(format); // HH:mm:ss');
      }
      // console.log('::customFormatter ',format, date, ret)
      return ret;
    },
    inDate(item) {
      let ret = true;
      if (item.start && item.finish) {
        let a1 = item.start.split('.');
        let a2 = item.finish.split('.');
        let d1 = a1[2] + a1[1] + a1[0];
        let d2 = a2[2] + a2[1] + a2[0];
        ret = this.today >= d1 && this.today <= d2;
      }
      // if(!item.tests || Object.keys(item.tests).length === 0){
      //   ret = false;
      // }
      // console.log(item, ret)
      return ret;
    },
    getListGroups() {
      let $this = this;
      this.parent.appOptionGet('group_list', false, true, true, true, 1000).then(
        (data) => {
          // console.log(':1: group_list data =', data)
          let group_list = [];
          if (data) {
            let detail_text = '';
            try {
              let arrData = [];
              // detail_text = data.DETAIL_TEXT.replace(/\u0009/g, " ");
              detail_text = this.parent.replaceDirectCode(data.DETAIL_TEXT);
              if ($this.parent.portal == 'makfa.bitrix24.ru') {
                detail_text = detail_text.replace('"GDP-2017"', 'GDP-2017'); //
                // detail_text = this.parent.replaceDirectCode(detail_text)
                // console.log(':: replace json')
              } else if ($this.parent.portal == 'ruco.bitrix24.ru') {
                //"u0421u0435u043cu0438u043du0430u0440 u0434u043bu044f u043fu0440u043eu0435u043au0442u0438u0440u043eu0432u0449u0438u043au043eu0432. u041fu0435u0440u043cu044c, u0418u0436u0435u0432u0441u043a. u041du043eu044fu0431u0440u044c 2023"
                detail_text = detail_text.replace('"u0421', '_u0421');
                detail_text = detail_text.replace('2023"', '2023_');
                // console.log(':: replace json')
              } else if ($this.parent.portal == 'next-step.bitrix24.ru') {
                detail_text = detail_text.replace('"Новый Брак"', 'Новый Брак'); //
              }
              if (typeof detail_text === 'string') {
                detail_text = detail_text.replace(/\u0009/g, ' ');
                try {
                  arrData = JSON.parse(detail_text);
                } catch (e) {
                  console.error(e);
                  // console.log(':!1!:StudentProfile error detail_text=', detail_text);
                }
              } else {
                arrData = detail_text;
              }
              let fltData = arrData.filter((el) => {
                return !!el.tests && !!el.USERS;
              });
              // console.log(':1: group_list fltData =', fltData)
              this.getUserGroups(fltData);
              // console.log(':!2!:StudentProfile arrData=', [...arrData]);
              // console.log(':!3!:StudentProfile fltData=', [...fltData]);
            } catch (e) {
              console.error(e);
              // console.log(':!0!:StudentProfile error detail_text=', detail_text);
            }
          }
          // else if(data){
          //   console.log(':!0!:StudentProfile error json=', data);
          // }
        },
        (err) => {}
      );
    },
    getUserGroups(group_list) {
      // console.log(':!1!:getUserGroups group_list=', group_list);
      this.userGroups = [];
      for (let itemGroup of group_list) {
        if (
          itemGroup.USERS &&
          itemGroup.USERS.find((el) => {
            return el.id == this.parent.user_current.ID;
          })
        ) {
          this.userGroups.push({
            id: itemGroup.ID,
            name: itemGroup.NAME,
            tests: itemGroup.tests,
            start: itemGroup.PROJECT_DATE_START ? dayjs(itemGroup.PROJECT_DATE_START).format('DD.MM.YYYY') : '',
            finish: itemGroup.PROJECT_DATE_FINISH ? dayjs(itemGroup.PROJECT_DATE_FINISH).format('DD.MM.YYYY') : '',
          });
        }
      }
      // console.log(':!2!:getUserGroups this.userGroups=', this.userGroups);
    },
    // getUserGroupsOld(group_list){
    //   console.log(':!1!:getUserGroups group_list=', group_list);
    //     let $this = this;
    //     let nn=0;
    //     this.userGroups=[];
    //     this.isLoading=true;
    //     BX24.callMethod('sonet_group.user.groups', {}, (res)=>{
    //         console.log('::getUserGroups sonet_group.user.groups=', res);
    //         if(res.answer.result) {
    //             let sonet_groups = res.answer.result;
    //
    //             for(let i in sonet_groups){
    //                 let sonet_group = sonet_groups[i];
    //                 // console.log('::getUserGroups sonet_group=', sonet_group);
    //
    //                 let itemGroup=group_list.find((element, index, array)=>{
    //                     if(element.ID == sonet_group.GROUP_ID) return true; else return false }, this);
    //                 console.log('::getUserGroups itemGroup=', itemGroup);
    //                 if(itemGroup){
    //                     this.userGroups.push({
    //                         id:itemGroup.ID,
    //                         name:itemGroup.NAME,
    //                         tests:itemGroup.tests,
    //                         start:itemGroup.PROJECT_DATE_START ? dayjs(itemGroup.PROJECT_DATE_START).format('DD.MM.YYYY') : '',
    //                         finish:itemGroup.PROJECT_DATE_FINISH ? dayjs(itemGroup.PROJECT_DATE_FINISH).format('DD.MM.YYYY') : '',
    //                     });
    //                 }
    //
    //             }
    //
    //         }
    //
    //           this.userGroups.sort(function compare(a, b) {
    //             if (parseInt(a.id) > parseInt(b.id)) {
    //               return -1;
    //             }
    //             if (parseInt(a.id) < parseInt(b.id)) {
    //               return 1;
    //             }
    //             return 0;
    //           });
    //
    //           // console.log(':!2!:getUserGroups userGroups=', this.userGroups);
    //           $this.isLoading=false;
    //
    //     });
    // },
    clickCourses() {
      this.isShowCourses = false;
      this.$nextTick(() => {
        this.isShowCourses = true;
      });
    },
    formatDate(timestamp) {
      return dayjs.unix(timestamp).format('DD.MM.YYYY');
    },

    saveProfile() {
      // console.log('::saveProfile', this.profile);
      let $this = this;
      $this.msg = '';
      $this.isLoading = true;
      const ext_certs = [];
      for (let item of this.ext_certs) {
        ext_certs.push({
          I: item.I,
          N: item.N,
          H: item.H,
        });
      }

      let method = 'entityItemAdd';
      let params = {
        ENTITY: 'uni_profile',
        PREVIEW_TEXT: this.parent.replaceQuotes(this.experience), //Опыт работы
        NAME: '' + this.parent.user_current.ID,
        PROPERTY_VALUES: {
          courses: JSON.stringify(this.courses),
          tests: JSON.stringify(this.tests),
          user: JSON.stringify(this.parent.user_current),
          certs: JSON.stringify(ext_certs),
          name: this.parent.replaceQuotes(this.name),
          last_name: this.parent.replaceQuotes(this.last_name),
          second_name: this.parent.replaceQuotes(this.second_name),
          work_position: this.parent.replaceQuotes(this.work_position),
          // departments: JSON.stringify(this.departments),
        },
      };
      //
      $this.parent.user_current.NAME = this.name;
      $this.parent.user_current.LAST_NAME = this.last_name;
      $this.parent.user_current.SECOND_NAME = this.second_name;
      $this.parent.user_current.WORK_POSITION = this.work_position;

      params.PROPERTY_VALUES = params.PROPERTY_VALUES || {};
      if (this.profile && parseInt(this.profile.ID) > 0) {
        params.ID = parseInt(this.profile.ID);
        params.PROPERTY_VALUES.points = this.profile.PROPERTY_VALUES.points;
        method = 'entityItemUpdate';
      }

      var url = $this.parent.bx24Init({AJAX_ACTION: method, params: params});
      $this.parent.bx24params.DETAIL_TEXT = this.parent.replaceQuotes($this.education);
      // console.log('::saveProfile params=', params);
      $this.parent.b24POST(url, $this.parent.bx24params, function (res) {
        // console.log('::saveProfile res=', res);
        if (res.result) {
          let id = 0;
          //
          if (params.ID) {
            id = params.ID;
            // console.log('The profile saved! '+id);
          } else {
            id = res.result;
            if (!$this.profile) {
              $this.profile = {};
            }
            $this.profile.ID = id;
            // console.log('New profile added! ' + id);
          }
        }
      });
    },

    getUserCourses(passedTests) {
      let $this = this;
      this.myCerts = [];
      this.myCerts_opts = [];
      let user_id = this.parent.user_current.ID;
      let format = this.appRegion == 'ru' ? 'DD.MM.YYYY' : 'YYYY-MM-DD';

      this.loading_user_courses = true;
      this.loading_user_tests = true;
      this.userCoursesLoading = true;
      let clearCache = this.tab_name === 'notifications' || this.tab_name === 'certs';
      // console.log(':**studentProfile**:getUserCourses user_id=',user_id, clearCache, this.tab_name);

      this.parent.uCoursesTestsGet('course', user_id, null, null, false, clearCache).then((userCourses) => {
        // console.log(':*** 1 ***:uCourses TestsGet courses=> ',user_id, userCourses);
        // this.user_courses = userCourses;
        userCourses.sort(function compare(a, b) {
          return parseInt(a.course_id) < parseInt(b.course_id);
        });

        this.user_courses = [];
        let last_course_id = 0;
        let one_course = [];
        for (let item of userCourses) {
          // if(item.course_id == 1342)
          // console.log(':***-1-***:uCourses TestsGet userCourses item=> ',item);

          if (item.passed && item.flags) {
            if ($this.parent.isJSON(item.flags)) {
              item.flags = JSON.parse(item.flags);
            }
            if (item.flags.issue_cert) {
              // console.log(':***-2-***:uCourses TestsGet userCourses item=> ', item);
              if (
                !this.myCerts.find((el) => {
                  return el.id == 'course_' + item.course_id;
                })
              ) {
                let itemDate = item.endTime ? dayjs(item.endTime * 1000).format(format) : '';
                itemDate = itemDate ? itemDate : item.startTime ? dayjs(item.startTime * 1000).format(format) : '';

                let itemCert = {
                  user_name: this.parent.user_current.NAME + ' ' + this.parent.user_current.LAST_NAME,
                  user_pos: this.parent.user_current.WORK_POSITION,
                  name: this.message[this.lang].course + ': ' + item.course_name,
                  id: 'course_' + item.test_id,
                  cert_id: item.flags.cert_id,
                  result: this.message[this.lang].passed,
                  date: itemDate,
                };

                if (
                  !this.myCerts_opts.find((el) => {
                    return (el) => id == 'course_' + item.test_id;
                  })
                ) {
                  this.myCerts.push(itemCert);
                  this.myCerts_opts.push(itemCert);
                }
              }
            }
          }

          if (last_course_id != item.course_id) {
            if (last_course_id) {
              this.user_courses.push(one_course);
            }
            one_course = {
              course: {
                course_id: item.course_id,
                course_name: item.course_name,
                program_chedule_id: item.program_chedule_id,
              },
              courses: [item],
            };
          } else {
            if (!one_course.courses) {
              one_course.courses = [];
            }
            one_course.courses.splice(0, 0, item);
            // one_course.courses.push(item);
          }
          last_course_id = item.course_id;
        }
        // console.log(':***-3-***:uCourses TestsGet this.myCerts_opts= ', this.myCerts_opts);
        this.user_courses.push(one_course);
        // console.log(":**2**:uCourses TestsGet this.user_courses=> ", this.user_courses);

        this.parent.uCoursesTestsGet('test', user_id).then((userTests) => {
          // console.log(":*3*:uCourses TestsGet tests=> ", userTests);
          this.parent.getScheduledCourses(user_id, null, userTests, true).then((schedules) => {
            this.userCoursesLoading = false;

            // console.log(':*****:uCourses TestsGet schedules=> ', schedules);
            // this.user_tests = userTests;
            userTests.sort(function compare(a, b) {
              if (a.test_id > b.test_id) {
                return 1;
              }
              if (a.test_id < b.test_id) {
                return -1;
              }
              return 0;
            });

            this.user_tests = [];
            let last_test_id = 0;
            let one_test = [];
            for (let item of userTests) {
              // if(item.test_id == 1342){
              //   console.log(':***-1-***:uCourses TestsGet item=> ',item);
              // }
              if (item.test_passed && item.flags && item.flags.issue_cert) {
                // console.log(':***-2-***:uCourses TestsGet item=> ',item);
                if (
                  !this.myCerts.find((el) => {
                    return el.id == 'test_' + item.test_id;
                  })
                ) {
                  let itemDate = item.endTime ? dayjs(item.endTime * 1000).format(format) : '';
                  itemDate = itemDate ? itemDate : item.startTime ? dayjs(item.startTime * 1000).format(format) : '';
                  let itemCert = {
                    user_name: this.parent.user_current.NAME + ' ' + this.parent.user_current.LAST_NAME,
                    user_pos: this.parent.user_current.WORK_POSITION,
                    name: this.message[this.lang].test + ': ' + item.test_name,
                    id: 'test_' + item.test_id,
                    cert_id: item.flags.cert_id,
                    result: item.test_result,
                    date: itemDate,
                  };
                  this.myCerts.push(itemCert);
                  this.myCerts_opts.push(itemCert);
                }
              }

              //Поищем в назначенных
              let foundIndex = schedules.tests.findIndex((el) => {
                return el.value == item.test_id;
              });
              if (foundIndex >= 0) {
                item.isScheduled = true;
              }

              if (last_test_id != item.test_id) {
                if (last_test_id) {
                  this.user_tests.push(one_test);
                }
                one_test = {
                  test: {
                    test_id: item.test_id,
                    test_name: item.test_name,
                    course_id: item.course_id,
                    isScheduled: item.isScheduled,
                    program_chedule_id: item.program_chedule_id,
                  },
                  tests: [item],
                };
              } else {
                one_test.tests.push(item);
              }
              last_test_id = item.test_id;
            }
            // console.log(':***-2-***:uCourses TestsGet user_courses=> ',this.user_courses);
            // console.log(':***-2-***:uCourses TestsGet user_tests=> ',this.user_tests);
            this.user_tests.push(one_test);
            // console.log(':***-3.1-***:uCourses TestsGet this.myCerts_opts= ', this.myCerts_opts);
            this.parent.getScheduledCourses(user_id, this.user_courses, this.user_tests, true).then((schedules) => {
              // console.log(':*1-sched-*:schedules=',schedules);

              this.scheduled_courses = [];
              for (let item of schedules.courses) {
                if (this.showInDates(item)) {
                  this.scheduled_courses.push(item);
                }
              }
              this.scheduled_tests = [];
              for (let item of schedules.tests) {
                if (this.showInDates(item)) {
                  this.scheduled_tests.push(item);
                }
              }
              // this.scheduled_courses = schedules.courses;
              // this.scheduled_tests = schedules.tests;
              // console.log(':*2-sched-*:scheduled_courses=',this.scheduled_courses);
              // console.log(':*2-sched-*:scheduled_tests=',this.scheduled_tests);
              this.loading_user_courses = false;
              this.loading_user_tests = false;

              this.iprUpdateScheduledCourses().then((res) => {
                this.parent.grpUpdateScheduledCourses(this.scheduled_courses.concat(this.scheduled_tests)).then((res) => {
                  if (res) {
                    // console.log(':*-ipr-*:res=',res);

                    this.scheduled_courses = this.scheduled_courses.concat(res.courses);
                    this.scheduled_tests = this.scheduled_tests.concat(res.tests);
                  }
                  // console.log(':*-sched-*:scheduled_courses=',this.scheduled_courses);
                  // console.log(':*-sched-*:scheduled_tests=',this.scheduled_tests);
                });
              });
            });
            // }, err=>{console.log(err)});
          }); //scheduled

          // this.userCoursesLoading=false;
        });
      });
    },
    iprUpdateScheduledCourses(publish) {
      let $this = this;
      return new Promise((resolve) => {
        this.parent.competenceGetList('ipr', '', null, 99999999, 100, this.parent.user_current.ID).then((res) => {
          if (res && res.list && res.list.length > 0) {
            // |сотрудник|наставник|руководитель|автор ипр|
            // let list = [];
            // for (let item of res.list) {
            //   let [persona,mentor,manager,author] = item.NAME.split("|");
            //   if(persona == this.parent.user_current.ID) {
            //     list.push(item);
            //   }
            // }
            let adSchedCourses = this.getListCoursesFromIpr(res.list, this.user_courses.concat(this.scheduled_courses));
            this.scheduled_courses = this.scheduled_courses ? this.scheduled_courses.concat(adSchedCourses) : adSchedCourses;
            // eslint-disable-next-line vue/no-mutating-props
            this.$emit('updSched', this.scheduled_courses);
          }
          resolve(true);
        });
      });
    },
    getListCoursesFromIpr(listIpr, listExcludes) {
      // console.log(':1:getListCoursesFromIpr listIpr, listExcludes=',listIpr, listExcludes);
      let now = dayjs().format('YYYY-MM-DD HH:mm');
      let courses = [];
      if (listIpr) {
        for (let iprItem of listIpr) {
          if (iprItem.published === 'Y' && iprItem.comps) {
            for (let compItem of iprItem.comps) {
              if (compItem.indicators) {
                for (let indItem of compItem.indicators) {
                  if (indItem.courses) {
                    for (let courseItem of indItem.courses) {
                      if (now <= courseItem.dln) {
                        if (
                          !courses.find((el) => {
                            return el.value == courseItem.value;
                          }) &&
                          !listExcludes.find((el) => {
                            return el.course && el.course.course_id == courseItem.value;
                          })
                        ) {
                          courses.push({
                            sched_id: 'ipr', //+courseItem.value,
                            isScheduled: true,
                            date_finish: courseItem.dln,
                            date_start: now,
                            type: 'course',
                            value: courseItem.value,
                            text: courseItem.label,
                          });
                        } else {
                          //Если курс уже проходится
                          //Поставим пометку в него
                          let idx = this.user_courses.findIndex((el) => {
                            return el.course.course_id == courseItem.value;
                          });
                          if (idx >= 0) {
                            this.user_courses[idx].course.sched_id = 'ipr';
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // console.log(':2:getListCoursesFromIpr courses=',courses);
      return courses;
    },
    toGoAgain(id, obj, sched_id = 0) {
      // console.log('::toGoAgain id=',id, obj);
      this.$emit(obj + 'ViewById', {
        id: id,
        nav: '',
        scr: 'studentProfile',
        sched_id: sched_id,
      });
    },
    loadMyAssessments(whoIs) {
      whoIs = whoIs ? whoIs : 'experts';
      let nn = 0;
      // console.log(':start:loadMyAssessments ');
      let $this = this;
      let today_start = dayjs().startOf('day').format('YYYY-MM-DD');
      let today_finish = dayjs().endOf('day').format('YYYY-MM-DD');
      let user_id = this.parent.user_current.ID;
      $this.assessmentList = $this.assessmentList ? $this.assessmentList : [];
      this.isLoading = true;
      let filter = {
        1: {
          LOGIC: 'AND',
          '%PROPERTY_persona': `%"id":"${user_id}"%`,
        },
      };
      if (whoIs === 'experts') {
        filter = {
          1: {
            LOGIC: 'AND',
            '%PROPERTY_experts': `%"id":"${user_id}"%`,
          },
        };
      }

      if (user_id > 100000) {
        filter = {
          1: {
            LOGIC: 'AND',
            '%PROPERTY_persona': `%"id":${user_id}%`,
          },
        };
        if (whoIs === 'experts') {
          filter = {
            1: {
              LOGIC: 'AND',
              '%PROPERTY_experts': `%"id":${user_id}%`,
            },
          };
        }
      }

      // '1': {
      //   LOGIC: "AND",
      //   // 'ACTIVE':'Y',
      //   '<=DATE_ACTIVE_FROM': today_start,
      //   '>=DATE_ACTIVE_TO': today_finish,
      // },
      // Ждем исправления бага от Саши с 20.06.2023
      // '2':{
      //   LOGIC: "OR",
      //   '%PROPERTY_persona': `%"id":"${user_id}"%`,
      //   '%PROPERTY_experts': `%"id":"${user_id}"%`
      // }
      // };
      let params = {
        ENTITY: 'uni_meth360180',
        SORT: {ID: 'DESC'},
        FILTER: filter,
        LIMIT: 5000,
      };

      // console.log(':*1*:loadAssessments params=', params);

      let url = $this.parent.bx24Init({AJAX_ACTION: 'entityItemGet', params: params});
      return new Promise((resolve) => {
        $this.parent.b24POST(
          url,
          $this.parent.bx24params,
          function (res) {
            // console.log(':*2*:loadAssessments res=', res);
            let arResult = res.result;
            if (arResult) {
              for (let i in arResult) {
                let item = arResult[i];
                // console.log(':*3*:loadAssessments item=', item);
                let persona = $this.parent.isJSON(item.PROPERTY_VALUES.persona) ? JSON.parse(item.PROPERTY_VALUES.persona) : {};
                let experts = $this.parent.isJSON(item.PROPERTY_VALUES.experts) ? JSON.parse(item.PROPERTY_VALUES.experts) : [];
                let scores = {};
                try {
                  scores = $this.parent.isJSON(item.PROPERTY_VALUES.scores) ? JSON.parse(item.PROPERTY_VALUES.scores) : {};
                } catch (e) {
                  console.error(e);
                }

                let competences = $this.parent.isJSON(item.DETAIL_TEXT) ? JSON.parse(item.DETAIL_TEXT) : '';

                let flags = $this.parent.isJSON(item.PROPERTY_VALUES.flags) ? JSON.parse(item.PROPERTY_VALUES.flags) : {};
                flags.com = flags.com ? flags.com : 'N';

                let isImExpert =
                  experts &&
                  experts.findIndex((element) => {
                    return element.id == user_id;
                  }) >= 0;
                let isImPersona = persona && persona.id == user_id;

                let isAnswered = false;
                if (scores) {
                  if (flags.acc === 'Y' && isImPersona && !isImExpert) {
                    isAnswered = true;
                  } else {
                    for (let i in scores) {
                      let item = scores[i];
                      let isBreak = false;
                      if (scores[i].hasOwnProperty('ind_c')) {
                        if (scores[i].selected.hasOwnProperty(user_id) && isImExpert) {
                          isAnswered = true;
                          isBreak = true;
                          break;
                        }
                      } //устарела убрать в 2024
                      else {
                        if (item.indicators) {
                          for (let j in item.indicators) {
                            let ind = item.indicators[j];
                            if (ind.selected.hasOwnProperty(user_id) && isImExpert) {
                              isAnswered = true;
                              isBreak = true;
                              break;
                            }
                          } //for j
                        }
                      } // 2024

                      if (isBreak) break;
                    }
                  }
                }

                // console.log(':4: isImExpert=',isImExpert,' isImPersona=',isImPersona,' isAnswered=',isAnswered);
                if (isImExpert || (isImPersona && isAnswered)) {
                  if (
                    !$this.assessmentList.find((el) => {
                      return el.id == item.ID;
                    })
                  ) {
                    $this.assessmentList.push({
                      id: item.ID,
                      name: item.NAME,
                      description: item.PREVIEW_TEXT,
                      date_start: item.DATE_ACTIVE_FROM,
                      date_finish: item.DATE_ACTIVE_TO,
                      persona: persona,
                      experts: experts,
                      scores: scores,
                      competences: competences,
                      active: item.ACTIVE,
                      ro: isAnswered,
                      isImExpert: isImExpert,
                      isImPersona: isImPersona,
                      comment: flags.com,
                      flags: flags,
                    });
                  }
                }
                // }
              }
              let count_not_answered = $this.assessmentList.filter((el) => {
                return !el.ro;
              }).length;
              $this.$emit('setCountMyAss', count_not_answered);
            }
            $this.isLoading = false;
            resolve(true);
          },
          'json'
        );
      });
    },

    countMyAssBackground() {
      let count_exp = 0;
      const uid = this.parent.user_current.ID;
      //Мои оценки 360 (не отвеченные)
      let filter = {
        1: {
          LOGIC: 'AND',
          '%STUFF': '%"' + uid + '"%',
        },
      };
      // console.log(':Мои оценки 360 (не отвеченные): filter=', filter);
      this.parent.loadPrograms('udegree360', 500, null, null, null, null, null, filter).then((res) => {
        // console.log(':Мои оценки 360 (не отвеченные): res=', res);

        for (let i in res) {
          let item = res[i].DETAIL_TEXT;
          if (this.parent.isJSON(item)) {
            item = JSON.parse(item);
            if (!item.scale) {
              continue;
            }
            if (!this.parent.isAllResultsFilled(item)) {
              count_exp++;
            }
          }
        }
        // console.log(":Мои оценки 360 (не отвеченные): count_exp=", count_exp);

        let countn_degree = 0;
        //Мои согласования (еще не согласованные)
        let filter = {
          1: {
            LOGIC: 'AND',
            '%PREVIEW_TEXT': `%"agree_id":"${uid}"%`,
          },
        };
        // console.log(":Мои согласования: filter=", filter);
        this.parent.loadPrograms('degree360', null, null, null, null, null, null, filter).then((res) => {
          // console.log(":Мои согласования: res=", res);
          for (let i in res) {
            let agreeID = this.parent.isJSON(res[i].PREVIEW_TEXT) ? JSON.parse(res[i].PREVIEW_TEXT) : res[i].PREVIEW_TEXT;
            if (agreeID) {
              for (let i in agreeID) {
                if (agreeID[i].agree_id == uid && !agreeID[i].agreed) {
                  countn_degree++;
                }
              }
            }
          }
          // console.log(":Мои согласования: countn_degree=", countn_degree);

          this.$emit('setCountMyDegree360', countn_degree + count_exp);
        });
      });
    },
    loadNotifications() {
      let $this = this;
      return new Promise(function (resolve, reject) {
        let notifications = [];
        if ($this.parent.user_current) {
          let user_id = $this.parent.user_current.ID;
          $this.parent.appSchedGet(false, 'uni_notifs', 'not_' + user_id, 500).then((data) => {
            if (data && Object.keys(data).length > 0) {
              // console.log('::Уведомления перенесены в новую таблицу')
              for (let item of data) {
                if ($this.parent.isJSON(item.DETAIL_TEXT)) {
                  let notification = JSON.parse(item.DETAIL_TEXT);
                  if (notification != '[]') {
                    notification.message = $this.parent.showBase64Text(notification.message);
                    notification.DATE_CREATE = item.DATE_CREATE;
                    notifications.push(notification);
                  }
                }
              }
              resolve(notifications);
            } else {
              resolve(notifications);
            }
          });
        } else {
          resolve(notifications);
        }
      });
    },
    saveToNotif() {
      this.loading = false;
      // console.log(':: saveToNotif ',this.notifications)
      let user_id = this.parent.user_current.ID;
      let arBatch = {};
      for (let i = this.notifications.length - 1; i >= 0; i--) {
        let item = this.notifications[i];
        let uniItem = {
          ENTITY: 'uni_notifs',
          NAME: 'not_' + user_id,
          PREVIEW_TEXT: 'opt_' + item.ID,
          DETAIL_TEXT: JSON.stringify(item),
        };
        arBatch['not_' + item.ID + '_' + user_id] = ['entityItemAdd', uniItem];
      }
      // console.log(':: saveToNotif arBatch=', arBatch)

      if (arBatch && Object.keys(arBatch).length > 0) {
        this.parent.callBatch(arBatch, (res) => {
          // console.log(':: saveToNotif res=', res);
        });
      }
    },
    /**
     * Опросы Quiz
     */

    resQuizDel(arg) {
      // console.log('::resQuizDel ', arg);
      let $this = this;
      $this.notifications[arg.index].loading = true;
      const index = $this.notifications.findIndex((el) => {
        return el.ID == arg.id;
      });
      if (index >= 0) {
        $this.notifications[index].STUFF = '';
        $this.notifications[index].ID = null;
        $this.$nextTick(() => {
          $this.notifications[index].ID = arg.id;
        });
      }
      $this.notifications[arg.index].loading = false;

    },
    sendQuiz(arg) {
      let $this = this;
      $this.notifications[arg.index].loading = true;
      // console.log(':-3-:sendQuiz arg=', arg);
      $this.notifications[arg.index].loading = false;
      const index = $this.notifications.findIndex((el) => {
        return el.ID == arg.id;
      });
      if (index >= 0) {
        $this.notifications[index].STUFF = arg.results;
        $this.notifications[index].ID = null;
        $this.$nextTick(() => {
          $this.notifications[index].ID = arg.id;
        });
      }
    },
    saveQuiz(res) {
      // console.log('::saveQuiz res=', res);
      let $this = this;
      this.addQuiz = false;
      $this.loading = true;
      $this.loadNotifications().then((res_notes) => {
        $this.parent.loadQizList().then((res_quiz) => {
          // console.log('::saveQuiz loadQizList res_quiz=', res_quiz);
          $this.parent.loadQizList(null, 'uni_message').then((res_mess) => {
            let mergedArray = $this.parent.mergeArrays(res_quiz, res_mess);
            $this.notifications = $this.parent.mergeArrays(res_notes, mergedArray);
            // console.log('::saveQuiz loadQizList $this.notifications=', $this.notifications);
          
            $this.loading = false;
          });
        });
      });
    },
    deleteQuiz(arg) {
      let $this = this;
      // console.log('::deleteQuiz ', arg);
      this.addQuiz = false;
      if (arg.index === 0 || arg.index > 0) {
        $this.notifications.splice(arg.index, 1);
      }
      this.addQuiz = false;
    },


    /**
     * Мои Сертифифкаты
     */
    filterFn_cert(val, update, abort) {
      let $this = this;
      update(() => {
        const needle = val.toLowerCase();
        $this.myCerts_opts = $this.myCerts ? $this.myCerts.filter((v) => v.name.toLowerCase().indexOf(needle) > -1) : [];
        // console.log('::filterFn_cert value=',$this.options_ru.value)
      });
    },
    showCert(value) {
      let $this = this;
      this.clearCanvas().then((res) => {
        if (this.certificate && Object.keys(this.certificate).length) {
          // console.log('::showCert ', this.certificate);
          let width = this.certificate.cert_format == 'A5' ? 310 : 620;
          let height = this.certificate.cert_format == 'A5' ? 438 : 877;
          if ($this.certificate && $this.certificate.cert_orient == 'Y') {
            height = this.certificate.cert_format == 'A5' ? 310 : 620;
            width = this.certificate.cert_format == 'A5' ? 438 : 877;
          }
          $this.canvas.setWidth(width);
          $this.canvas.setHeight(height);
          //
          let url = this.certificate.href ? this.certificate.href : this.certificate.data;
          if (url) {
            fabric.Image.fromURL(url, function (oImg) {
              // console.log('::showCert oImg=', oImg)
              let img = oImg; //.set({ left: 0, top: 0 ,width:width,height:height});
              if ($this.certificate.scale) {
                let scale = $this.certificate.scale > 5 ? $this.certificate.scale / 100 : $this.certificate.scale;
                img.scale(scale);
                img.set('selectable', false);
                // $this.scale = $this.certificate.scale;
              }

              // let backWidth = oImg.width*oImg.scaleX;
              $this.canvas.add(img);
              $this.canvas.sendToBack(img);
              $this.reDrowText(value, width); // Добавим надписи
              $this.canvas.renderAll();
            });
          } else {
            // console.log('::showCert без картинки');
            $this.reDrowText(value, width); // Добавим надписи
            $this.canvas.renderAll();
          }
        } else {
          // console.log('::showCert: no certificate ', this.certificate);
        }
      });
    },
    clearCanvas() {
      let $this = this;
      return new Promise((resolve) => {
        // console.log('::clearCanvas ',this.canvas.width, this.canvas.height )
        const context = $this.canvas.getContext('2d');
        context.clearRect(0, 0, $this.canvas.width, $this.canvas.height);
        let objs = $this.canvas.getObjects();
        // console.log(':1:clearCanvas objs=', objs )
        for (let i in objs) {
          $this.canvas.remove(objs[i]);
        }
        // console.log(':2:clearCanvas objs=', this.canvas.getObjects());

        $this.$nextTick(() => {
          $this.canvas.renderAll.bind($this.canvas);
          resolve(true);
        });
      });
    },
    reDrowText(value, backWidth) {
      if (this.certificate.cert_opts_fio == 'Y') this.textCert('fio', value.user_name, backWidth);
      if (this.certificate.cert_opts_pos == 'Y') this.textCert('pos', value.user_pos, backWidth);
      if (this.certificate.cert_opts_name == 'Y') this.textCert('name', value.name, backWidth);
      if (this.certificate.cert_opts_score == 'Y') this.textCert('score', this.message[this.lang].result + ': ' + value.result);
      if (this.certificate.cert_opts_date == 'Y') this.textCert('date', value.date);
      if (this.certificate.cert_opts_text == 'Y') this.textCert('text', this.certificate.cert_text);
    },

    savePNG() {
      let $this = this;
      this.canvas.getElement().toBlob(function (blob) {
        saveAs(blob, `${$this.certificate.cert_name}.png`);
      });
    },
    savePDF() {
      // console.log('::savePDF this.certificate=', this.certificate);
      let imgData = canvas.toDataURL('image/png');
      let orient = this.certificate.cert_orient == 'Y' ? 'l' : 'p';
      // let doc = new jsPDF({orientation: orient, unit: 'mm', format: this.certificate.cert_format=='A5'?'a5':'a4'});
      let width = this.certificate.cert_format == 'A5' ? 105 : 210;
      let height = this.certificate.cert_format == 'A5' ? 148 : 297;
      if (this.certificate.cert_orient == 'Y') {
        height = this.certificate.cert_format == 'A5' ? 105 : 210;
        width = this.certificate.cert_format == 'A5' ? 148 : 297;
      }
      let doc = new jsPDF({orientation: orient, unit: 'mm', format: [width, height]});
      doc.addImage(imgData, 'PNG', 0, 0, width, height);
      doc.save(`${this.certificate.cert_name}.pdf`);
      // More info: https://github.com/MrRio/jsPDF
    },
    textCert(mode, txt, backWidth) {
      // console.log('::textCert =', mode, txt);
      if (!txt) return;
      let top = this.certificate[`id_${mode}_top`] ? this.certificate[`id_${mode}_top`] : 100,
        left = this.certificate[`id_${mode}_left`] ? this.certificate[`id_${mode}_left`] : 100;
      let scaleX = this.certificate[`id_${mode}_scaleX`] ? this.certificate[`id_${mode}_scaleX`] : 1,
        scaleY = this.certificate[`id_${mode}_scaleY`] ? this.certificate[`id_${mode}_scaleY`] : 1;
      let font_size = this.certificate.cert_font_size ? parseInt(this.certificate.cert_font_size) : 20;

      let text = null;
      if (mode === 'text') {
        //Доп. текст
        text = new fabric.Textbox(txt, {
          id: `id_${mode}`,
          fontSize: font_size,
          width: 500,
          left: left,
          top: top,
          fill: this.certificate.cert_font_color ? this.certificate.cert_font_color : this.certificate.cert_dark_mode == 'Y' ? '#ffffff' : '#777777',
          scaleX: scaleX,
          scaleY: scaleY,
        });
      } else {
        text = new fabric.Text(txt, {
          id: `id_${mode}`,
          fontSize: font_size,
          left: left,
          top: top,
          fill: this.certificate.cert_font_color ? this.certificate.cert_font_color : this.certificate.cert_dark_mode == 'Y' ? '#ffffff' : '#444444',
          scaleX: scaleX,
          scaleY: scaleY,
        });
      }

      // if(backWidth) {
      //   text.left = backWidth/2 - text.width*scaleX/2;
      // }

      this.canvas.add(text);
      this.canvas.bringToFront(text);
      // this.certificate[`cert_opts_${mode}`]='Y';

      this.canvas.renderAll();
    },
    itemCompNames(item) {
      let ret = [];
      for (let i in item.competences) {
        ret.push(item.competences[i].name);
      }
      return ret;
    },
    /**
     * Сообщения
     */
    saveMessage(arg) {
      let $this = this;
      this.addMessage = false;
      $this.loading = true;
      $this.loadNotifications().then((res_notes) => {
        $this.parent.loadQizList().then((res_quiz) => {
          $this.parent.loadQizList(null, 'uni_message').then((res_mess) => {
            let mergedArray = $this.parent.mergeArrays(res_quiz, res_mess);
            $this.notifications = $this.parent.mergeArrays(res_notes, mergedArray);
            $this.loading = false;
          });

        });
      });
    },
    deleteMessage(arg) {
      let $this = this;
      if (arg.index === 0 || arg.index > 0) {
        $this.notifications.splice(arg.index, 1);
      }
      this.addMessage = false;
    },

    initChart(assessment, iComp = 0) {
      let show_fio = !assessment.flags || assessment.flags.hide_fio !== 'Y';
      this.chartIndex = iComp;
      this.chartOptions = {
        chart: {
          height: 550,
          type: 'radar',
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1,
          },
        },
        title: {text: ''},
        stroke: {
          width: 2,
        },
        fill: {
          opacity: 0.1,
        },
        markers: {
          size: 0,
        },
        xaxis: {
          categories: [],
        },
        legend: {
          show: show_fio,
        },
      };

      // let iComp=this.chartIndex?this.chartIndex:0;
      // console.log('::initChart show_fio,chartIndex,assessment=',show_fio,this.chartIndex, assessment);
      this.tabActiveName = 'chart';
      let name = assessment.persona[0] ? assessment.persona[0].name : assessment.persona.name;
      this.chartOptions.title.text = this.message[this.lang].chart_title + ' ' + name;
      //
      this.legend = [];
      let showLegend = false;
      this.chartOptions.xaxis.categories = [];
      this.series = [];
      this.table_items = [];
      let n = 1;
      for (let j in assessment.competences[iComp].cats) {
        let item = assessment.competences[iComp].cats[j];
        let cat = item.text;
        // console.log('::initChart cat =',j,item, cat);
        this.legend.push(n + '. ' + cat);
        if (String(cat).trim().length > 8) {
          cat = n + '. ' + cat.substring(0, 8) + '..';
          showLegend = true;
        } else {
          //легенда помещается выводить не надо
          cat = n + '. ' + cat;
        }
        this.chartOptions.xaxis.categories.push(cat);
        // this.table_fields.push({key:''+n, label:cat});
        n++;
      }
      // console.log(':2:initChart xaxis=',this.chartOptions.xaxis.categories);

      if (!showLegend) {
        this.legend = false;
      }
      // console.log(':2:initChart scores=',assessment.scores);
      //
      let userScores = {},
        userText = {};
      if (assessment.scores && Object.keys(assessment.scores).length > 0) {
        if (assessment.scores[0].hasOwnProperty('ind_c')) {
          for (let item of assessment.scores.filter((el) => {
            return el.ind_c == iComp;
          })) {
            // console.log(':: item=', item);
            let ind_i = item.ind_i;
            for (let uid in item.selected) {
              userScores[uid] = userScores[uid] ? userScores[uid] : [];
              userText[uid] = userText[uid] ? userText[uid] : [];

              userScores[uid].push(item.selected[uid] * 10);
              userText[uid].push(assessment.competences[iComp].cats[ind_i].text);
            }
          }
        } //устаревшее убрать в 2024
        else {
          for (let iId in assessment.scores[iComp].indicators) {
            let indicator = assessment.scores[iComp].indicators[iId];
            for (let uid in indicator.selected) {
              userScores[uid] = userScores[uid] ? userScores[uid] : [];
              userText[uid] = userText[uid] ? userText[uid] : [];

              userScores[uid].push(indicator.selected[uid].value.index * 10);
              userText[uid].push(indicator.selected[uid].value.text);
            }
          }
        } //2024
      }
      // console.log(':3:initChart userScores=',userScores);
      // console.log(':4:initChart userText=',userText);

      for (let expert of assessment.experts) {
        this.series.push({
          name: show_fio ? expert.name : '',
          data: userScores[expert.id] ? userScores[expert.id] : [],
          scores_text: userText[expert.id] ? userText[expert.id] : [],
        });
      }
      // console.log(':6:initChart series=',this.series);

      this.chartOptions.markers = {
        size: 4,
        hover: {
          size: 8,
        },
      };

      if (assessment.competences[iComp].cats && assessment.competences[iComp].cats[0]) {
        let item = assessment.competences[iComp].subcats[assessment.competences[iComp].cats[0].value];
        let tickAmount = Object.keys(item).length;

        this.chartOptions.yaxis = {
          tickAmount: tickAmount,
        };
      }

      this.showChart = true;
      // console.log('::initChart chartOptions=', this.chartOptions);
    },
  },
  components: {
    quiz,
    Comments,
    Message,
    reports: defineAsyncComponent(() => import('./Reports.vue')),
    ipr: defineAsyncComponent(() => import('./IPR.vue')),
    eventsList: listOfEvents,
    listPrograms,
    degree360ListLk,
    degree360List,
  },
};
</script>

<style scoped>
.accord_row {
  border-bottom: lightgray dashed 1px;
  /*background-color: #ffffff;*/
  text-align: left;
}
.accord_row2 {
  /*background-color: #cccccc;*/
  border-bottom: lightgray dotted 1px;
  text-align: left;
  margin-bottom: 5px;
}

.prof_font {
  font-size: 0.9rem;
  color: #444;
}
.prof_label {
  font-size: 1.1rem;
  font-weight: bold;
  color: #444;
}
.prof_descr {
  font-size: 0.8rem;
  color: #777;
}

table {
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 70%;
  width: 70%;
  border-collapse: collapse;
  text-align: left;
}
th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px solid #6678b1;
  padding: 10px 8px;
}
td {
  border-bottom: 1px solid #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}
tr:hover td {
  color: #6699ff;
}
</style>
