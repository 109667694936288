/**
 * AmoCrm
 */
let amoUsersState=null;
let amoDepartments=[];
export function  amocrmCurrentUser($this){
    const qp = $this.getQueryParams();
    console.log('::amocrmCurrentUser qp=',qp);
    let user_current={};
    return new Promise(async resolve=>{
        if(qp?.amocrm !== 'Y'){
          $this.amocrm=false;
          resolve(false)
        }else{
          $this.amocrm=true;//Это точно amocrm
          const url=`${$this.apiurl}api/amo-currentUser`;
          $this.POST(url, qp, async function (res) {
            if(res){
              if($this.isJSON(res)){
                res = JSON.parse(res);
              }
              console.log('::amocrmCurrentUser res=',res);
              if(res.hasOwnProperty('result') || res.status == 401){
                console.error('::Не получен текущий юзер, возможно требуется переустановка приложения');
                $this.noBX24 = true;
                resolve({});
              }else{
                user_current.ID = res.id;
                user_current.email = res.email;
                const [firstName, middleName, lastName] = res.name.split(' ');
                user_current.NAME = firstName;
                user_current.SECOND_NAME = middleName;
                user_current.LAST_NAME = lastName;
                user_current.UF_DEPARTMENT = '';
                if(res?._embedded?.groups?.length>0){
                    user_current.UF_DEPARTMENT=res._embedded.groups.map(el=>el.id);
                }

                $this.member_id = res.member_id;
                $this.portal = qp.domain;
                $this.lang = res.lang || "ru";
                $this.isAdmin = res?.rights?.is_admin;
                resolve(user_current)
              }
            }else{
                resolve({})
            }
          })
        }
    });
};

export function getUsersAMO($this, isActive, arDepartments, usersIds, color) {
  console.log('::getUsersAMO',isActive, arDepartments, usersIds);
  return new Promise(async (resolve, reject) => {
    if(!amoUsersState){
      amoUsersState = await getUsersAmocrm($this, isActive);
    }
    console.log('::getUsersAMO amoUsersState=',amoUsersState);

    if(usersIds?.length>0){
      let filtered = amoUsersState.filter(el=>usersIds.includes(el.id))
      resolve(filtered);
    }else{
      let filtered = amoUsersState.filter(user =>
        user.deps?user.deps.some(dep => arDepartments.includes(dep)):false
      );
      resolve(filtered);        
    }
  });
};

function getDepartments(users){
  const departments = users.flatMap(item =>
    item._embedded.groups.map(group => ({
      parent: "",
      text: group.name,
      uf_head: '',//item.id.toString(), // Используем id родительского элемента
      value: group.id.toString(),
    }))
  );
  
  // Убираем дубликаты по value
  amoDepartments = Array.from(new Map(departments.map(item => [item.value, item])).values());
  console.log('::getDepartments ',amoDepartments);
}
    

export function getUsersAmocrm($this, isActive=false, search=''){
    return new Promise((resolve) => {
      if(!amoUsersState){
        $this.callMethod('amo-users', {}).then(res=>{
          console.log('::getUsersAmocrm res=',res);
          let users=[];
          if(res?._embedded?.users?.length>0){
            for(let item of res._embedded.users){

              let UF_DEPARTMENT = '';
              if(item?._embedded?.groups?.length>0){
                UF_DEPARTMENT=item._embedded.groups.map(el=>el.id);

              }

              let position = item?.position;
              let user = {
                id: item.id,
                name: item.name,
                email: item?.email || '',
                deps: UF_DEPARTMENT,
                photo: '',
                type: '',
                pos: ''
              }
              users.push(user);

            }

            getDepartments(res._embedded.users);

          }
          amoUsersState = [...users];
          if(search){
            resolve(amoUsersState.filter(el=>{
              return el.name.indexOf(search)!==-1;
            }));
          }else{
            resolve(amoUsersState);
          }
        });
      }else{
        console.log('::getUsersAmocrm amoUsersState=',amoUsersState);
        if(search){
          let filtered = amoUsersState.filter(user => 
            user.name.toLowerCase().includes(search.toLowerCase()) || 
            user.email.toLowerCase().includes(search.toLowerCase())
          );
          console.log('::getUsersAmocrm filtered=',search,filtered);
          resolve(filtered);
        }else{
          resolve(amoUsersState);
        }
      }
    });

  };

   export function extraFilterFnAMO($this, needle){
        return new Promise(resolve => {
          getUsersAmocrm($this, true, needle).then(users=>{
            let list=[];
            if(users?.length>0) {
              for (let i in users) {
                let item = users[i];
                item.value = item.id;
                item.text = item.name;
                if(item.name)
                  list.push({value: item.id, text: item.name, name:item.name, id: item.id, deps: item.deps, departments:item.departments, pos:item.pos, head:item.head, email:item.email});
              }
            }
            console.log('::extraFilterFnAMO list=',list)
            resolve(list);
          });
        });
  
      };

      export function getDepartmentsAMO($this) {
        // console.log('::getDepartments');
        return new Promise(async (resolve, reject) => {
          if(!amoUsersState){
            amoUsersState = await getUsersAmocrm($this);
          }
          resolve(amoDepartments);
        });
      };